
@import '../../../styles/common/variables.scss';
.container-video-call {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: aliceblue;
}
.text-appointment-video-call {
font-weight: 600;
}
.link-video-call {
    color: white;
    background-color: #409cce;
    padding: 10px;
    border-radius: 6px;
    margin-top: 20px;
    box-shadow: $boxshadow-light;
    transition: box-shadow 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
        box-shadow: $boxshadow-expanded;
        color: white;
      }
}
.img-principal-video-call {
    width: 134px;
    height: 134px;
    margin-bottom: 20px;
}