@import '../../../styles/common/variables.scss';
.container-without-documents {
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: solid 1px rgb(183, 185, 185);
    box-shadow: $boxshadow-light;
}
.without-documents {
    color: rgb(183, 185, 185);
    font-size: 16px !important;
}
.map-appointment-container {
    height: 400px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
}