@import "../../../styles/common/variables.scss";

.delta-buttons {
  display: flex;
  justify-content: flex-end;

  button+button {
    margin-left: 16px;
  }

  padding: 24px 0px 40px;
}

.form--simple-styled {
  .section-form-title {
    font-size: 1.4em;
    font-weight: 500;
  }

  .fieldset-container {
    border: 1px solid #172E56;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    margin-top: 40px;

    &.error {
      border: 1px solid $red;
      color: red;
    }

    legend {
      margin-top: -10px;
      padding-bottom: 8px;
      display: inline;
      font-weight: 500;

      .legend-text {
        display: inline-block;
        padding: 0px 8px;
        background-color: white;
      }
    }

    .input-dropdownlist {
      margin-bottom: 24px;

      select {
        padding: 10px 0px 10px 20px;
      }
    }

    .input-block,
    .input-dropdownlist {
      legend {
        padding-bottom: 0px;
      }

      input {
        color: black;
      }
    }

  }

  textarea {

    resize: vertical;
  }

  &.searcher {
    padding: 16px 0;
    background-color: #D1DDF1;

    .btns-container {

      padding: 0px 15px;

    }

    .input-block {
      margin-bottom: 16px;

      .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
      .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
      .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
      .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
      .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
      .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
        background: white;
        padding-left: 8px;
      }

      input,
      select,
      textarea {
        background-color: white;
        color: $dark-gray;

        &:focus:not([disabled]) {
          border-bottom: 2px solid $accent-company;
        }
      }
    }
  }

  fieldset {
    // reset default
    margin: 0;
    padding: 0px;
    border: 0px;

    legend {
      display: block;
      border: 0px;
      font-size: 1.2em;
      font-weight: 400;
      color: #172E56;
    }
  }

  .container-inputdate {
    display: flex;


  }

  .input-block__label {
    display: inline-block;
    margin-bottom: 4px;
    font-size: 1em;
    font-weight: 500;
    color: #172E56;
  }

  .input-block {
    margin-bottom: 24px;

    &.error {

      input,
      select,
      textarea {
        border-bottom-color: $red ;
      }
    }

    input,
    select {
      min-height: 24px;
      padding-top: 4px;
      padding-bottom: 4px;
      box-sizing: border-box;
      line-height: 24px;
    }

    select {
      &+select {
        margin-top: 8px;
      }
    }

    input,
    select,
    textarea {
      box-sizing: content-box;
      width: calc(100% - 24px);
      padding: 4px 12px;
      overflow: hidden;
      background-color: $light-gray;
      // background-color: white;
      border: 0px;
      border-bottom: 2px solid $soft-gray;
      transition: border-bottom .2s ease-in-out;
      color: $dark-gray;

      &::placeholder {
        color: $soft-gray;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $soft-gray;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $soft-gray;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $soft-gray;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $soft-gray;
      }

      &:focus {
        // border: yellow;
        border-bottom: 2px solid $accent-company;
      }

      &:disabled {
        color: $soft-gray;
      }
    }

    textarea {
      overflow-y: auto;
      min-height: 38px;
    }

    input::placeholder {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .input-block .actors_submissionDate {
    margin-bottom: 0px;
  }

  .input-block--code {
    display: flex;
    padding: 0px;
    flex-direction: column;

    .input-block--code__legend {
      margin-bottom: 4px;
      font-size: 1em;
      font-weight: 500;

    }

    .container-inputs-cno-code {
      display: flex;

    }

    input {
      display: inline-block;
    }

    .code {

      margin-right: 8px;

    }
  }

  .inputs--group {
    .input-block--radio-check__label {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      min-height: 20px;
      align-items: center;

      input {
        margin: 0px;
      }

      .text {
        margin-left: 8px;
        margin-bottom: 0;
      }

      &+.input-block--radio-check__label {
        margin-left: 8px;
        margin-top: 0px;
      }
    }
  }

  // TO REVIEW
  .input-block--radio-check__group {
    min-height: 24px;
    padding: 6px 0px;
    display: flex;
    align-items: center;

    &.d-block {
      display: block;

      .input-block--radio-check__label {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        min-height: 20px;
        align-items: center;

        input {
          margin: 0px;
        }

        .text {
          margin-left: 8px;
        }

        &+.input-block--radio-check__label {
          margin-left: 0px;
          margin-top: 8px;
        }
      }
    }

    .input-block--radio-check__label {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;

      .text {
        margin-left: 6px;
      }

      input[type="radio" i] {
        margin: 2px 8px 0px 0px;
      }

      &+.input-block--radio-check__label {
        margin-left: 24px;
      }

      input {
        width: auto;
        min-height: auto;
      }
    }

  }

  .cnae-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 16px;
    // padding-right: 16px;
  }

  .fieldset-input-block--group {
    border: 1px solid $soft-gray; // RED
    border-radius: 4px;
    padding: 8px;

    .fieldset-input-block--group__legend {
      display: block;
      font-size: 1em;
      width: auto;
      margin: 0px 0px 0px 8px; ///
      padding: 0px 8px; ///
    }

    .inputs--group {
      padding-top: 4px;
      padding-bottom: 6px !important;
      padding-left: 8px !important;
    }
  }

  .input-block {
    &.input-block--fieldset {
      padding: 8px;
      border: 1px solid $soft-gray; // RED
      border-radius: 4px;

      .input-block--fieldset__legend {
        margin-bottom: 4px;
        font-size: 1em;
        width: auto;
        margin: 0; ///
        padding: 0px 8px; ///
        font-weight: 500;
      }

      .input-block--fieldset__group {
        display: flex;

        @media (max-width: 768px) {
          display: block;
        }

        input {
          display: inline-block;
          width: calc(50% - 14.5px);

          &:first-child {
            margin-right: 8px;
          }

          @media (max-width: 768px) {
            display: block;
            width: calc(100% - 24px);
            margin-bottom: 24px;

            &:first-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

.input-block-structure--loading {
  height: 34px;
  width: 100%;
  background-color: $light-gray;
  border-bottom: 2px solid $soft-gray;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}

input:focus-visible,
select:focus-visible,
textarea:focus-visible,
button:focus-visible,
a:focus-visible {
  outline: none;
  box-shadow: $boxshadow-expanded;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  box-shadow: 0px;
  -webkit-box-shadow: 0px;
  transition: background-color 5000s ease-in-out 0s;
}

.form-erros-validation {

  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;

  h4 {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 16px;

    li {
      list-style-type: disc;

      &:last-child {
        p {
          margin-bottom: 0px;

        }
      }
    }

    background-color: #e7dab7;
    max-height: 400px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 20px;
      /* width of the entire scrollbar */
      width: 8px;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar-track {
      background: #e4dfd1;
      /* color of the tracking area */
      margin-left: 4px;
      margin-right: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-blue-pure-company;
      /* color of the scroll thumb */
      border: 3px solid $dark-blue-pure-company;
      /* creates padding around scroll thumb */
      border-radius: 8px;

      /* roundness of the scroll thumb */
      &:hover {
        background-color: $dark-blue-extra-company;
        /* color of the scroll thumb */
        border: 3px solid $dark-blue-extra-company;
        /* creates padding around scroll thumb */
      }
    }

    li {
      list-style-type: circle;
      ;

      a {

        &:hover {
          font-weight: 500;
        }

      }

      p {
        margin: 0px;
      }

      &+li {
        margin-top: 10px;
      }
    }
  }
}

.input-error {
  border: 1px solid red !important;

}

.error {
  color: red;
}

.text-danger-li {
  border: 1px solid red;
  background: rgb(247, 203, 203);
  color: #6e7070;
  font-weight: 400;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  padding: 6px;
  box-shadow: $boxshadow-light;
  transition: box-shadow 0.2s ease-in-out;
}

.error-server {
  border: 1px solid red;
  background: rgb(247, 203, 203);
  color: #6e7070;
  font-size: 30px;
  font-weight: 400;
  margin-left: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  padding: 6px;
  box-shadow: $boxshadow-light;
  transition: box-shadow 0.2s ease-in-out;
}

.title-errors {

  color: $accent;
}

.text-danger-li:hover {
  cursor: pointer;
  box-shadow: $boxshadow-expanded;
}

.error-table-accident {
  font-size: 8px;
}

.container-btn-formedituser {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.text-help-point-one {
  color: #9a9a9b;
  font-size: 12px;
  margin-bottom: 0px;
}