@import '../../styles/common/variables.scss';
.card-link-new-application{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: calc(100% - 24px);
    width: 100%;
    height: 155px;
    padding: 16px;
    border: 1px solid $light-gray;
    box-shadow: $boxshadow-light;
    margin-bottom: 24px;
    cursor: pointer;
    transition: box-shadow .2s ease-in-out;

    &:hover {
      box-shadow: $boxshadow-expanded;
    }
}
.title-new-application {
    color: rgb(186, 187, 188);
}
.img-plus {
    width: 60px;
    height: 60px;
}