@import '../../../styles/common/variables.scss';

.news-icon {
  height: 64px;
  &.notice {
    background-image: url('../../../img/icons/svg/popups/notice.png');
  }
  &.news {
    background-image: url('../../../img/icons/svg/popups/_news-icon-draw.svg');
  }
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.swiper-box-container {
  &.disabled-bullets {
    .swiper-container {
      .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
        display: none;
      }
    }
  }
}

.swiper-content {
  padding: 32px 24px;
  h1 {
    color: $accent;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
  }
  background-color: $white;
}
// reset default swiper theme
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  background: $accent;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  background: $accent;
}

.swiper-container {
  &:before {
    position: absolute;
    content: '';
    width: 24px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(to right, $white, transparent);
  }
  &:after {
    position: absolute;
    content: '';
    width: 24px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(to left, $white, transparent);
  }
}