// records
@import '../../../../styles/common/variables.scss';

.record-detail-card_block {
  display: flex;
  min-height: 84px;
  margin-bottom: 24px;
  box-shadow: $boxshadow-light;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    cursor: auto;
    &:hover {
      box-shadow: $boxshadow-light;
    }
    .info-record-container {
      .title {
        color: $soft-gray;
      }
    }
  
    .icon-record-type-container {
      &.consultation,
      &.rehab,
      &.operation,
      &.hospitalization,
      &.documentation,
      &.image {
        background-color: $soft-gray;
      }
    }
  }
  &:hover {
    box-shadow: $boxshadow-expanded;
  }
  .icon-record-type-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    &.documentation {
      &.bg-color--01{
        background-color: #00BFB2;
      }
      &.bg-color--02{
        background-color: #00A5BF;
      }
      &.bg-color--03{
        background-color: #0086BF;
      }
      &.bg-color--04{
        background-color: #015c83;
      }
      &.bg-color--05{
        background-color: #013951;
      }
      
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../../img/icons/record/record-card-05.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .icon-record-type {
      width: 60px;
      height: 40px;
    }
  }
  .info-record-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    width: 100%;
    padding: 16px 16px 16px 16px;
    border: 1px solid $light-gray;
    .title {
      font-weight: 500;
      font-size: 1.2em;
      text-transform: uppercase;
      margin: 0px;
    }
    .description {
      margin-bottom: 0px;
    }
  }
}
@media (max-width: 768px) {
  .record-detail-card_block .info-record-container .title {
    font-size: 0.9em;
  }
}
.info-delta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 16px 16px 16px 16px;
  border: 1px solid $light-gray;
  .title {
    font-weight: 500;
    font-size: 1.2em;
    text-transform: uppercase;
    margin: 0px;
  }
  .description {
    margin-bottom: 0px;
  }
 }