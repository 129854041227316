@import '../../../styles/common/variables.scss';
// Breadcrumbs
.breadcrumbs {
  margin-top: 16px;
  text-align: right;
  .breadcrumb-separator {
    padding: 0px 8px;
  }
  .breadcrumb-container {
    &:last-child {
      font-weight: 500;
      pointer-events: none;
    }
  }
  .breadcrumb-link {
    letter-spacing: .5px;
  }
}