.container-table-cookies{

    background-color: #7dc8f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin-bottom: 20px;
}
.container-title-table {
    display: flex;
    text-align: center;
    font-weight: bold;
    padding: 10px;
}
.container-items {
    display: flex;
    text-align: center;
   

}
.items-table {
    border: solid 1px white;
    padding: 10px;
    font-size: 13px;
}
.link-cookies {
    color: #3894c6;
    text-decoration: underline;
   
}
.link-cookies:hover {
    cursor: pointer;
}