//reset
/* Estilos Generales */
body {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
  }
    
  html {
    margin: 0px;
    padding: 0px;
  }
  
  ul {
    margin: 0px;
    padding: 0px;
  }
  
  ul li {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
  }
  
  h1,
  h2,
  h3,
  h4 {
    margin: 0px;
  }
  
  h1 {
    font-weight: 300;
    font-size: 3em;
    // line-height: 1.2em;
    font-size: 32px;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  a:active,
  a:hover {
    outline: 0;
    text-decoration: none;
    color: inherit;
  }

  button {
      background: none;
      border: 0px;
      outline: none;
      cursor: pointer;
      &:focus {
        outline: none;
      }
  }

  label, input:button, a, button {
    cursor: pointer;
  }

  input, textarea {
    border:none;
    background-image:none;
    background-color:transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
  }