@import '../../../styles/common/variables.scss';

.legal-warning-popup {
  background-color: $dark-gray;
  h2 {
    padding-bottom: 16px;
    font-weight: 400;
    color: $accent;
  }
  p {
    font-weight: 300;
    color: $white; 
  }
  a {
    font-weight: 500;
    color: $accent;
  }
  .btn-container {
    text-align: center;
      @media (max-width: 768px) {
        padding-top: 32px;
      }
  }
  .btn-box {
    min-width: 120px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
}