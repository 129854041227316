// records
@import '../../../styles/common/variables.scss';

.record-detail-card_block {
  display: flex;
  min-height: 84px;
  margin-bottom: 24px;
  box-shadow: $boxshadow-light;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    cursor: auto;
    &:hover {
      box-shadow: $boxshadow-light;
    }
    .info-record-container {
      .title {
        color: $soft-gray;
      }
    }
    .icon-record-type-container {
      &.consultation,
      &.rehab,
      &.operation,
      &.hospitalization,
      &.documentation,
      &.emergencies,
      &.image {
        background-color: $soft-gray;
      }
    }
  }
  &:hover {
    box-shadow: $boxshadow-expanded;
  }
  .icon-record-type-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    &.consultation {
      background-color: #06b6bb;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/svg/historial/historial-card-01.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.rehab {
      background-color: #05a7c0;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/svg/historial/historial-card-02.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.operation {
      background-color: #409cce;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/svg/historial/historial-card-03.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.hospitalization {
      background-color: #407ece;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/svg/historial/historial-card-04.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.emergencies {
      background-color: #0650b0;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/svg/historial/ambulance.png");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.documentation {
      background-color: #4061ce;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/svg/historial/historial-card-05.svg");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &.image {
      background-color: #013951;
      .icon-record-type {
        background-size: contain;
        background-image: url("../../../img/icons/record/grisMedium.png");
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    src\img\icons\record\x-rays.png
    .icon-record-type {
      width: 60px;
      height: 40px;
    }
  }
  .info-record-container {
    width: 100%;
    padding: 16px 16px 16px 16px;
    border: 1px solid $light-gray;
    .title {
      font-weight: 500;
      font-size: 1.2em;
      text-transform: uppercase;
    }
    .description {
      margin-bottom: 0px;
    }
  }
}
.container-isextinct {
  position: relative;
}
.extinct{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px black;
  border-radius: 5px;
  background-color: rgba(241, 244, 251, 0.9);
  text-align: center;
  
}
// .extinct:hover{
//   // background-color:  rgba(231, 237, 248, 0.5);
//   // box-shadow: $boxshadow-light;
//   display: none;
// }
.img-extinct{
  width: 50px;
  height: 50px;
}
.text-extinct {
  color: #969696;
  font-weight: bold;
  margin-top: 10px;
}
.text-isVideoCall {
  color: #969696;
  font-weight: bold;
  margin: 0;
}

