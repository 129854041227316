@import '../../../styles/common/variables.scss';

// Breadcrumbs
.breadcrumbs {
  margin-top: 16px;
  text-align: right;
  .breadcrumb-separator {
    padding: 0px 8px;
  }
  .breadcrumb-container {
    &:last-child {
      font-weight: 500;
      pointer-events: none;
    }
  }
  .breadcrumb-link {
    cursor: pointer;
    letter-spacing: .5px;
  }
}

// PageMenu
.page-menu {
  min-height: 110px;
  display: flex;
  align-items: center;
  &.bottom-box-info {
    min-height: 100px;
    align-items: flex-end;
  }

  .content-menu {
    min-height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $light-gray;

    .btns-header-container {
      padding-right: 16px;
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      .e-icons {
        font-size: 14px;
        padding-right: 8px;
      }
      a {
        display: flex;
        img {
          height: 24px;
        }
      }
    }
  }
  h1 {
    padding: 8px 24px;
    font-size: 2em;
    color: $dark-blue-extra;
    line-height: normal;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &.photo {
    background: #eee url("../../../img/back-02.jpg");
    background-size: cover;
    background-position: center;
    .breadcrumbs {
      color: $white;
    }
    .content-menu {
      padding-bottom: 24px;
      background-color: rgba(0, 0, 0, 0);
      h1 {
        color: $white;
      }
    }
  }
  .btns-header-container {
    padding-right: 0px;
    &:hover {
      cursor: pointer;
    }
    .btn-toggle-view {
      background-color: $light-gray;
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .e-icons {        
        margin-right: 0px;
        padding-right: 0px;
        &.e-Level4 {
          position: relative;
          height: 8px;
          width: 10px;
          &:before {
            position: absolute;
            left: -2px;
            top: -3px;
          }
        }
      }
      & + .btn-toggle-view {
        margin-left: 8px;
      }
      &:hover {
        background-color: $medium-blue;
        .e-icons,
        i {
          color: $white;
        }
      }
      &.selected {
        background-color: $medium-blue;
        pointer-events: none;
        &:hover {
          cursor: default;
          background-color: $medium-blue;
        }
        .e-icons,
        i {
          color: $white;
        }
      }
    }
    .btn-text {
      text-align: right;
      padding-right: 16px;
      font-weight: 600;
      text-transform: uppercase;
      i {
        margin-right: 8px;
      }
    }
  }
}

@media (max-width: 768px) {
  .page-menu h1 {
    font-size: 1.35em;
  }
}
