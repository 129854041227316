// Grids
// general
.variable-select__grid {
  .e-row.selectable-row {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }

    .e-rowcell:first-child {
      border-left: 4px solid #06B6BB;
    }
  }

  .e-row.unselectable-row {
    .e-rowcell:first-child {
      border-left: 4px solid $light-gray;
    }
  }

  .e-row.general-row {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }

    .e-rowcell:first-child {
      border-left: 4px solid #234581;
    }
  }
}

// individual
.current-request-transaction__grid {
  .e-row {
    .e-rowcell:first-child {
      border-left: 4px solid $light-gray;
    }
  }

  .e-row.status__current {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }

    .e-rowcell:first-child {
      border-left: 4px solid #06B6BB;
    }
  }
}

.records__grid {
  .e-row.record-type__occupational-disease {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }

    .e-rowcell:first-child {
      border-left: 4px solid #06B6BB;
    }
  }

  .e-row.record-type__general {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }

    .e-rowcell:first-child {
      border-left: 4px solid #234581;
    }
  }

  .e-row.record-type__cessation_of_autonomous_activity {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }

    .e-rowcell:first-child {
      border-left: 4px solid #7dacfc;
    }
  }
}

.record-detail__communications-grid,
.appointments__grid,
.communications__grid,
.record-detail-visit__grid,
.link-rows__grid {
  .e-row {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }
  }
}

// .record-detail-visit__grid {
//   margin-top: 20px;
// }
.row-clear .e-grid .e-gridcontent tr.e-row .e-rowcell:first-child {
  border-left: 4px solid #7dacfc !important;
}

.row-bright .e-grid .e-gridcontent tr.e-row .e-rowcell:first-child {
  border-left: 4px solid #06B6BB !important;
}

.row-dark .e-grid .e-gridcontent tr.e-row .e-rowcell:first-child {
  border-left: 4px solid #234581 !important;
}
.notifications__grid {
  .e-row.link__row {
    &:hover {
      cursor: pointer;

      .e-rowcell {
        font-weight: 500;
        color: $accent !important;
      }
    }
  }
}