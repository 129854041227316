// searcher
.e-toolbar .e-toolbar-items {
  background-color: white;
  border: 0px;
  .e-toolbar-right {
    border: 0px;
    width: 100%;
  }
}
.e-toolbar .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child {
  margin: 0px;
  width: 100%;
  background: $light-gray;
}
.e-grid .e-toolbar {
  border: 0px;
}
.e-grid .e-toolbar-items .e-toolbar-item.e-search-wrapper .e-search {
  width: 100%;
  background: $white;
  opacity: 1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.e-input-group.e-search.e-input-focus {
  &::before {
    background: $accent;
  }
}
.e-control input.e-input,
.e-control .e-input-group input,
.e-control .e-input-group input.e-input {
  padding-left: 16px;
  padding-right: 16px;
}
span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input {
  &::before {
    background: $accent;
  }
  &::after {
    background: $accent;
  }
}
.e-input-group:not(.e-float-icon-left):not(.e-float-input) {
  &::before {
    background: $accent;
  }
  &::after {
    background: $accent;
  }
}
.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input {
  select>option:checked {
    color: $accent;
  }
}
.e-float-input.e-input-focus.e-input-group.e-ddl.e-control.e-keyboard>.e-float-text {
  color:$accent;
}
// .e-input-group:not(.e-float-icon-left):not(.e-float-input)::before {
//   color: $accent;
// }
span.e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard {
  font-size: 1.2em;
}
.e-grid .e-search-icon {
  &::before {
    font-size: 18px;
    color: $accent;
  }
}
.e-input-group .e-input-group-icon:last-child {
  margin-right: 8px;
}
// pagination color
.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: $accent;
}

// grid
.e-grid {
  border: 0px;
  .e-headertext {
    font-size: 1.2em;
    font-weight: 500;
    color: black;
  }
}
.e-gridheader.e-lib.e-droppable {
  border-top: 0px;
  border-bottom: 1px solid #e0e0e0;
}
.e-grid .e-headertext {
  color: $accent;
}
.e-grid .e-pager {
  border-bottom: 0px;
}
.e-grid .e-rowcell {
  font-size: 14px;
}

// select
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before, .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before, .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
    background: $accent;
}
.e-dropdownbase .e-list-item.e-active.e-hover {
  color: $accent;
}
.e-dropdownbase .e-list-item.e-active {
  color: $accent;
}

// spiner
.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: $accent;
}