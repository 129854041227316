@import '../../../styles/common/variables.scss';

// Message
.message_block {

  box-shadow: $boxshadow-light;

  &+.message_block {
    margin-top: 16px;
  }

  &.user {
    // border: 1px solid $accent; // or #CFE9F6

    .message-header {
      background-color: $gray-blue; // or #CFE9F6
      
    }
  }

  .message-header {
    background-color: #00A5BF;
    padding: 8px 16px;
    border-radius: 10px 10px 0px 0px;
    font-size: 18px;

    .message-author {
      font-weight: 500;
      color: white;
    }

    .message-date {
      font-size: 16px;
      font-weight: 400;
      padding-left: 32px;
      color: whitesmoke;
    }
  }
.subject-body {
  background-color: white;
  padding: 6px 10px 6px 10px;
  margin-left: 26px;
  width: 80%;
  box-shadow: $boxshadow-light;
}
  .message-body {
    display: flex;
    // justify-content: center;
    align-items: left;
    flex-wrap: wrap;
    padding: 6px 10px 6px 10px;
    font-size: 14px;
    width: 80%;
    min-height: 200px;
    margin-top: 10px;
    margin-left: 16px;
    color: rgb(112, 109, 109);
    // border-radius: 0px 10px 10px 10px;
    // background-color: rgb(227, 231, 232);
    background-color: white;
    box-shadow: $boxshadow-light;


  }

  .files_block {
    padding: 10px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3px;
    align-items: center;
   

   
    .file-link {
      display: flex;
      padding: 4px 16px;
      border-radius: 24px;
      transition: all .2s ease-in-out;
      background-color: white;
    
      .file-text {
        margin-left: 20px;
        color: #00A5BF;
      }
    
      .file-icon-message, .file-icon {
        display: inline-block;
        top: 0;
        bottom: 0;
        left: 16px;
        margin: auto;
        width: 14px;
        height: 16px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    
      &:hover {
        box-shadow: $boxshadow-light;
        cursor: pointer;
      }
    }
    
    @media (max-width: 767px) {
      .file-link {
        padding: 2px 8px;
        border-radius: 12px;
    
        .file-text {
          margin-left: 10px;
          font-size: 12px; /* Ajusta el tamaño del texto */
        }
    
        .file-icon-message, .file-icon {
          width: 10px;
          height: 12px;
          left: 8px;
        }
      }
    }
  }

}

.label-message {
  padding: 6px;
  margin-left: 16px;
  color: rgb(137, 135, 135);
}
.label-message-files {
  padding: 6px;
  margin-left: 16px;
  color: rgb(137, 135, 135);
}

.container-subject {
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  background-color: aliceblue;
  margin-top: 20px;
  padding-top: 10px;
}
.container-message-list {
  box-shadow: $boxshadow-light;

}
.container-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: auto;
  background-color: aliceblue;
  padding-bottom: 30px;
}
.container-files-message {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #eeeeee;
  padding: 10px 10px;
  
}