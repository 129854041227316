@import '../../../styles/common/variables.scss';
// Settings page
.user-data-block {
  min-height: calc(100% - 24px);
  border-bottom: 1px solid $dark-gray;
  margin-bottom: 24px;
  color: $dark-gray;
  .label {
    font-size: 22px;
    font-weight: 500;
  }
  .text {
    display: flex;
    justify-content: space-between;
    min-height: 26px;
    font-size: 22px;
    font-weight: 300;
    text-transform: capitalize;
    &.email, &.address {
      text-transform: none;
    }
    a,
    button {
      display: flex;
      margin-right: 16px;
      float: right;
      color: $accent;
      transition: all 0.2s ease-in-out;
      font-weight: 300;
      &:hover {
        color: $link-blue;
      }
    }
  }
}
.change-password {
  min-height: calc(100% - 24px);
  border-bottom: 1px solid $dark-gray;
  margin-bottom: 24px;
  color: $dark-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  .label {
    font-size: 22px;
    font-weight: 500;
  }
  a {
    display: flex;
    font-size: 24px;
      margin-right: 16px;
      float: right;
      color: $accent;
      transition: all 0.2s ease-in-out;
      font-weight: 300;
      &:hover {
        color: $link-blue;
      }
  }
}
.container-btn-edit-use {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

}

.text-btn-edit-user {
  display: flex;
  font-size: 24px;
    margin-right: 16px;
    float: right;
    color: $accent;
    transition: all 0.2s ease-in-out;
    font-weight: 300;
 
}
