@import '../../../../styles/common/variables.scss';

.TabsDeltaWithLeaveDetail {
  .e-tab .e-tab-header .e-toolbar-items {
    margin-bottom: 40px;
    border-bottom: 1px solid $soft-gray;
  }
  .tabs-h-container {
    .tabs-h {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      border-bottom: 1px solid black;
      &::-webkit-scrollbar {
        height: 8px;               /* width of the entire scrollbar */
        width: 40px;
        scrollbar-width: thin;
      }
      
      &::-webkit-scrollbar-track {
        background: white;        /* color of the tracking area */
        margin-left: 4px;
        margin-right: 4px;
      }
  
      &::-webkit-scrollbar-thumb {
        background-color: $accent-company;    /* color of the scroll thumb */
        border: 3px solid $accent-company;  /* creates padding around scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
        &:hover {
          background-color: $dark-blue-extra-company;    /* color of the scroll thumb */
          border: 3px solid $dark-blue-extra-company;  /* creates padding around scroll thumb */
        }
      }

      .tab-item {
        position: relative;
        display: inline-block;
        margin-bottom: -1px;
        cursor: pointer;
        margin-right: 4px;
        &:hover {
          span {
            color: black;
            transition: color .2s ease-in-out;
            &::after {
              content: '';
              background-color: rgba(6, 182, 188, 0.15);
              position: absolute;
              left: 0;
              top: -10px;
              right: 0;
              bottom: -3px;
              z-index: -1;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              transition: background-color .2s ease-in-out;
            }
          }
        }
        span {
          font-weight: 400;
          padding: 8px 12px;
          color: $accent;
          &::after {
            content: '';
            background-color: rgba(6, 182, 188, 0);
            position: absolute;
            left: 0;
            top: -10px;
            right: 0;
            bottom: -3px;
            z-index: -1;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }
        &.active {
          pointer-events: none;
          background-color: white;
          border: 1px solid black;
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
          border-bottom: none;
          margin-bottom: -1px;
          padding: 8px 4px 4px 4px;
          span {
            font-weight: 500;
            color: black;
          }
        }
      }
      @media (max-width: 1200px) {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        border-color: #b1b1b1;
        &::-webkit-scrollbar {
          height: 4px;
        }
        &::-webkit-scrollbar-track {
          margin-left: 0px;
          margin-right: 0px;
        }
        .tab-item {
          min-width: max-content;
          padding: 10px 4px 8px 4px;
          margin-bottom: 0px;
          &.active {
            background-color: rgba(6, 182, 188, 0.33);
            border: 0px;
            border-radius: 0px;
            border-bottom: none;
            margin-bottom: 0px;
            padding: 10px 4px 8px 4px;
            span {
              font-weight: 500;
              color: black;
            }
          }
        }
      }
    }
  }
  .tabs-content {
    margin: 0px -15px;
  }
}