// colors
$accent: #409CCE;
$medium-blue: #347BA2;
$link-blue: #234581; // color login password
$dark-blue-extra: #172E56;
$light-blue: #85D4FF;
$extra-light-blue: #D8F1FF;
$light-gray: #eeeeee;
$gray-blue: #D1DDF1;
$white: #ffffff;
$soft-gray: #b1b1b1;
$medium-gray: #636363;
$dark-gray: #4E4E4E;
$red: #D1005D;

//company
$accent-company: #3A5FA1;
$dark-blue-extra-company: #29426D;
$dark-blue-pure-company: #172E56;

// shadows
$boxshadow: 0 2px 2px rgba(0, 0, 0, 0.32);
$boxshadow-expanded: 0 4px 4px rgba(0, 0, 0, 0.32);
$boxshadow-light: 0 2px 2px rgba(0, 0, 0, 0.2);