@import '../../../styles/common/variables';

.remarkable {
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 32px;
  margin-bottom: 20px;
  padding: 16px;
  &:first-child {
    margin-top: 0px;
  }
  .remarkable-title {
    display: table;
    padding: 0px 10px;
    font-size: 1.2em;
    margin-top: -1.6em;
    line-height: 1.6em;
    margin-bottom: 16px;
    background-color: white;
  }
  .remarkable-title2 {
    width:fit-content;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    font-size: 1.2em;
    margin-top: -1.6em;
    line-height: 1.6em;
    margin-bottom: 16px;
    background-color: white;
  }
   .remarkable-Subtitle {
    display: table;
    font-size: 1.2em;
    margin-top: -1.6em;
    line-height: 1.6em;
    margin-bottom: 16px;
    background-color: white;
  }
}
.item-pair {
  display: flex;
  .item {
    width: 50%;
  }
  .item:first-child {
    border-right: 4px solid white;;
  }
  .item:last-child {
    border-left: 4px solid white;;
  }
}
.item {
  margin-bottom: 16px;
  &-checkbox {
    height: 100%;
    display: flex;
    align-items: center;
    .item-label {
      margin: 0px;
      margin-left: 8px;
    }
  }
  .item-label {
    margin-bottom: 0px;
    display: inline-block;
    width: 100%;
  }
  .item-value {
    display: inline-block;
    width: 100%;
    min-height: 32px;
    padding: 4px 8px;
    margin-top: 4px;
    background-color: $light-gray;
    &+.item-value {
      border-left: 8px solid white;
    }
  }
}
.container-entornos {
  border: solid 1px black;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.container-isactive  {

  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #EEEEEE;
  margin-top: 0px;
}
.container-base-reguladora {
  margin-top: 0px;
}
