@import '../../../styles/common/variables.scss';
// Modal
.modal-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  visibility: visible;
  .bg {
    width: 100%;
    height: 100%;
    background-color: rgba($dark-gray, 0.6);
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s linear, opacity 0.5s linear, background-color 0.5s ease-in-out;
  }
  &.collapsed {
    visibility: hidden;
    .bg {
      visibility: hidden;
      opacity: 0;
      background-color: rgba(32, 32, 32, 0);
    }
    .modal_block {
      visibility: hidden;
      opacity: 0;
      top: -100vh;
    }
  }
  .container-modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    overflow-y: auto; // test in small screen
  }
  .modal_block {
    visibility: visible;
    opacity: 1;
    position: relative;
    top: 80px;
    right: 0;
    left: 0;
    padding: 32px 16px 48px 16px;
    border-radius: 6px;
    margin: auto;
    background-color: $white;
    box-shadow: $boxshadow-expanded;
    transition: all 0.5s ease-in-out;
    // width: 400px;
    width: 80vw;
    max-width: 600px;
    @media (max-width: 576px) {
      width: calc(100vw - 32px);
      padding-top: 16px;
    }
    .btn-close {
        margin-bottom: 16px;
        text-align: right;
      @media (min-width: 576px) {
        position: absolute;
        top: -20px; right: -20px;
        padding: 10px;
        border-radius: 100%;
        margin-bottom: 0px;
        background-color: #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      button {
        @media (max-width: 576px) {
          width: 24px;
          height: 24px;
        }
        width: 20px;
        height: 20px;
        padding: 0px;
        background-image: url('../../../img/icons/svg/hamburger/cerrar-cruz-gris.svg');
        background-size: cover;
      }
      img {
        width: 20px;
      }
    }
    .modal-content-block{
      padding: 0px 40px 0px;
      .modal-header-block {
        text-align: center;
        .modal_title {
          margin-bottom: 24px;
          color: $accent;
        }
        .modal_subtitle {
          margin-bottom: 24px;
          color: $dark-gray;
        }
      }
      .modal_title {
        text-align: center;
        margin-bottom: 24px;
        color: $accent;
      }
      .revalidate_title {
        text-align: center;
        margin-bottom: 24px;
        color: $dark-gray;
      }
      .modal_subtitle {
        text-align: center;
        margin-bottom: 24px;
        color: $dark-gray;
      }
    }
    form {
      .error {
        font-size: 12px;
        color: red;
      }
    }
    .ghost-form {
      height: 207px;
    }
    .confirm-message {
      text-align: center;
      h2 {
        color: $accent;
      }
      .img-container {
        margin-bottom: 24px;
        height: 60px;
        img {
          height: 100%;
        }
      }
      .btn-resolve-container {
        margin-top: 32px;
      }
    }
  }
  .btns-container {
    &.right {
      text-align: right;
    }
  }
}

.modal_title {
  margin-bottom: 24px;
  color: $accent;
}
.modal_subtitle {
  margin-bottom: 24px;
  color: $dark-gray;
}

.message-idle-session {
  .message-idle-session__em {
    font-size: 2em;
    color: $accent;
  }
}