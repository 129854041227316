@import '../../../styles/common/variables.scss';

// TableAppointment
.icon-calendar-appointment-detail {
  width: 45px;
  height: 45px;

}

.table-appointment-block {
  border: 1px solid $light-gray;

  @media (max-width: 992px) {
    // margin-bottom: 24px;
  }

  .table-appointment-header {
    padding: 16px 24px;
    border: 1px solid $dark-blue-extra;
    background-color: $dark-blue-extra;

    .table-appointment_title {
      font-size: 18px;
      text-transform: uppercase;
      color: $white;
    }
  }

  .table-row-info {
    padding: 24px;
    border-top: 1px solid $light-gray;
    display: flex;
    flex-wrap: wrap;

    .info-content {
      display: flex;
      width: 50%;

      .text-container {
        padding-left: 16px;
        padding-right: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin: 0px;
        }

        .text-header {
          font-weight: 600;
        }
      }
    }
  }
}
.icon {
  width: 45px;
  height: 45px;
}
.img-table-appointment-icon {
  width: 100%;
  height: 100%;
}