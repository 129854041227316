@import '../../../styles/common/variables.scss';
// Footer
.footer-block {
  background-color: $medium-gray;
  .footer-content {
      padding: 40px 0px;
      .col-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media (max-width: 768px) {
              align-items: center;
          }
          .logo {
              width: 100%;
              @media (max-width: 576px) {
                  width: auto;
              }
          }
          &.tel-info-container {
              text-align: center;
              @media (max-width: 768px) {
                  padding: 32px 0px;
              }
              .tel-link {
                  img {
                      width: 48px;
                      margin-bottom: 10px;
                  }
                  span {
                      display: block;
                      margin-top: 8px;
                  }
                  .tel-title {
                      font-size: 18px;
                      color: $gray-blue;
                  }
                  .tel-number {
                      font-size: 24px;
                      color: $light-blue;
                  }
              }
          }
          &.nav-container {
              .nav_link {
                text-align: center;
                  color: $gray-blue;
                  &+.nav_link {
                      margin-top: 10px;
                  }
              }
          }
      }
  }
  .copy {
      padding: 8px 0px;
      background-color: $dark-gray;
      margin: 0px;
      text-align: center;
      color: $light-gray;
  }
}