@import '../../../styles/common/variables.scss';

.btn-avisos {
  background-color: $accent;
  color: white;
  padding: 6px;
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.32);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
}

.container-btn-avisos {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 50px;
  bottom: 140px;
  z-index: 9;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: rgba(64, 156, 206, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;

}

.count-avisos {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(247, 152, 9, 0.7);
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  z-index: 3;
  font-size: 12px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: 1px, solid rgb(174, 168, 168);
  box-shadow: $boxshadow-expanded;
}

.img-btn-avisos {
  position: relative;
  padding: 6px;
  width: 50px;
  height: 50px;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
    filter: drop-shadow(0 0 0 transparent);
  }

  50% {
    transform: translateY(-10px);
    filter: drop-shadow(0 15px 10px rgba(0, 0, 0, 0.5));
  }

  100% {
    transform: translateY(0);
    filter: drop-shadow(0 0 0 transparent);
  }
}

.float {
  animation: floatAnimation 3s ease-in-out infinite;
}

.container-btn-avisos:hover {
  cursor: pointer;
}

.container-btn-avisos::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 50px;
  transform: translateX(-50%);
  width: 50px;
  height: 15px;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.container-btn-avisos:hover::after {
  opacity: 1;
}

.container-btn-avisos:hover {
  animation-play-state: paused;
}

@media only screen and (max-width: 600px) {
  .container-btn-avisos {
    width: 40px;
    height: 40px;
    right: 25px;
    bottom: 78px;
  }

  .count-avisos {
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(235, 164, 59, 0.7);
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 6px;
  }

  .img-btn-avisos {
    width: 25px;
    height: 25px;
  }

}
.title-modal-notice-detail {
  color: $accent;
  
}
.header-modal-notice-detail {

  box-shadow: $boxshadow-light;

}
.title-files-notices {
  color: $accent;
  font-weight: 300;
  margin-bottom: 20px;
  font-size: 18px !important;
}
.btn-close-notice-detail {
  background-color: $accent;
  border: none;
  color: white;
  &:hover {
    background-color: $medium-blue;
    color: whitesmoke;
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-20px);
  }
  60% {
      transform: translateY(-10px);
  }
}

.bounce {
  animation: bounce 0.5s;
}