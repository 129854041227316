@import '../../../styles/common/variables.scss';

.centers-dropdown {
  margin-bottom: 40px;
}

.centers-block {

  .section-title {
    margin-bottom: 32px;
  }

  .center-item {
    // background-color: #eeeeee;
    padding: 20px;
    box-shadow: $boxshadow;
    margin: auto;
    width: 100%;
    margin-bottom: 40px;

    h3 {
      margin-bottom: 8px;
      // font-size: 1.2em;
      font-weight: 500;
      color: $accent;
    }

    address {
      text-transform: uppercase;
      font-weight: 500;
    }

    ul {
      list-style: none;
    }

    ul>li {
      position: relative;
      margin-left: 24px;

      &::before {
        position: absolute;
        left: -16px;
        top: 10px;
        content: '';
        width: 4px;
        height: 1px;
        background-color: black;
      }
    }

    .container-info-center {
      display: flex;
      flex-direction: column;

    }

    .icon-text {
      display: flex;
      flex-direction: row;
    }

    .center-day-date {
      p {
        margin-left: 25px;
        margin-bottom: 0;
      }
    }

    .center-info-header {
      display: flex;
      align-items: center;

      .center-info-header-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &.icon--01 {
          background-image: url("../../../img/icons/svg/centers/01.svg");
        }

        &.icon--02 {
          background-image: url("../../../img/icons/svg/centers/02.svg");
        }

        &.icon--03 {
          background-image: url("../../../img/icons/svg/centers/03.svg");
        }

        &.icon--05 {
          background-image: url("../../../img/icons/svg/centers/05.svg");
        }

        &.icon--06 {
          background-image: url("../../../img/icons/svg/centers/06.svg");
        }
      }

      .icon {
        padding-right: 8px;
        color: $accent;
      }

      .header {
        font-weight: 500;
        padding-right: 4px;
      }
    }
  }
}

.center-schedule {
  .center-info-header {
    margin-top: 1rem;
  }

  ul {
    margin-bottom: 1rem;
    list-style: none;
  }

  legend {
    margin-top: 16px;
    margin-bottom: 4px;
    font-size: 1em;
  }
}

.container-data-detail-center {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #eeeeee;
  width: 100%;
}

.label-items {
  display: flex;
  flex-direction: row;
}

.text-label-item {
  margin-left: 20px;
}

.name-center-solimat {
  color: $accent;
  font-weight: bold;
}

// .container-map-centers {


// }
.container-map-detail-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header {
  text-align: center;
  font-size: 14px;
}

.text-muted a {
  font-size: 14px;
  color: #409CCE;
  font-weight: bold;
  margin: auto;
}

.container-aviso {
  padding: 30px;
  margin-bottom: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.img-warning {
  width: 50px;
  height: 50px;

}

.descripcion-warning {
  font-size: 12px;
  padding: 10px;
  background-color: rgba(238, 238, 238, 0.5);
  font-weight: bold;

}

.span-warning {
  font-size: 30px !important;
  margin-left: 10px;
  color: white;
  text-shadow: 1px 0px 1px #818181, 0px 1px 1px #b1b0b0, 2px 1px 1px #7c7b7b, 1px 2px 1px #6e6e6e, 3px 2px 1px #858383
}
.container-fechas {
  display: flex;
  flex-direction: row;
  gap: 30px;
  background-color: rgba(238, 238, 238, 0.5);
}
.fecha-aviso {
  font-size: 12px;
  color: #6e6e6e;
  margin-bottom: 0px;
  font-weight: bold;
  padding: 10px;
}