.link-btn-icon_block {
  position: relative;
  margin-bottom: 60px;
  border-radius: 4px;
  .link-btn-icon {
    width: 100%;
    display: block;
    background-color: #fff;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000030;
    border: 1px solid #999999;
    border-radius: 4px;
    transition: all .2s ease-in-out;
    &:hover {
      border-color: $link-blue;
    }
    img {
      padding: 40px;
    }
    &:disabled {
      opacity: .8;
      cursor: unset;
      &:hover {
        border: 1px solid #999999;
      }
    }
  }
  .link-btn-icon_label {
    margin-top: 24px;
    font-size: 24px;
    color: #666;
  }
}

.btn-box {
  padding: 10px 16px;
  border-radius: 4px;
  box-shadow: $boxshadow-light;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in;
  &.btn-accent {
    background-color: $accent;
    color: $white;
    border: none;
    &.company {
      background-color: $dark-blue-pure-company;
      &:hover {
        background-color: $dark-blue-extra-company;
      }
    }
    &:hover {
      background-color: $medium-blue;
    }
    &:disabled {
      background-color: $soft-gray;
      cursor: unset;
      &:hover {
        background-color: $soft-gray;
      }
    }
    &.loading {
      min-width: 120px;
      opacity: 1;
      transition: opacity .2s ease;
      .loader-btn {
        opacity: 0;
        transition: opacity .2s ease;
      }
      &.active {
        background-color: $medium-blue;
        box-shadow: none;
        height: 44px;
        color: inherit;
        vertical-align: middle;
        pointer-events: none;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        &:hover {
          pointer-events: none;
        }
        .loader-btn {
          opacity: 1;
          position: relative;
          width: 16px;
          height: 16px;
          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: inherit;
            height: inherit;
            border-radius: 50%;
            animation: 1.3s spin linear infinite;
          }
          &:before {
            // color: $accent;
            color: rgb(255, 255, 255);
            transform: rotateX(70deg);
          }
          &:after {
            // color: $dark-blue-extra;
            color: rgb(255, 255, 255);
            transform: rotateY(70deg);
            animation-delay: .4s;
          }
        }        
      }
    }
  }
  &.btn-clear {
    color: $dark-gray;
    border: 1px solid rgb(196, 193, 193);
    box-shadow: $boxshadow;
    font-weight: 700;
    margin-right: 10px;
    &.clear-accent {
      color: $accent;
      &:hover {
        color: $medium-blue;
      }
    }
    &:disabled {
      color: $soft-gray;
      &:hover {
        color: $soft-gray;
        cursor: auto;
      }
    }
  }
  &.btn-clear:hover {
    color: $soft-gray;
  }

}

.btn-text {
  color: $accent;
  transition: background-color 0.2s ease-in;
  &.btn-gray {
    color: $medium-gray;
    &:hover {
      color: $dark-gray;
      cursor: pointer;
    }
  }
  &:hover {
    color: $medium-blue;
    cursor: pointer;
  }
  &.icon-right {
    position: relative;
    &::after {
      width: 12px;
      content: url("../../img/link-right-blue.svg");
      position: absolute;
      right: -16px;
      top: 0px;
      color: inherit;
      transition: all 0.3s ease-in-out;
    }
    &:hover::after {
      right: -20px;
    }
  }
}

.btn-round {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 40px;
  border: 1px solid #eaaa00;
  background: #f9e3ab;
  box-shadow: $boxshadow;
  font-size: 1.2em;
  font-weight: 700;
  color: #be8b02;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .5s ease-in-out;
  &:hover {
    color: #be8b02;
    background: #fce9b9;
    box-shadow: $boxshadow-expanded;
  }
  &.blue {
    background: $accent;
    color: $white;
    border: 1px solid $medium-blue;
    &:hover {
      background: $medium-blue;
    }
  }
}

.link-bold, .link-bold:not([href]):not([tabindex]) {
  color: $accent;
  font-weight: 500;
  color: $accent;
  text-transform: uppercase;
  transition: color .2s ease-in-out;
  cursor: pointer;
  &:hover {
    color: $link-blue;
  }
}

.download-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url("../../img/icons/svg/botones/download.svg");
  background-repeat: no-repeat;
  background-position: center center;
}
.btn-text-icon:hover {
  cursor: pointer;
  .btn-text {
    color: $medium-blue;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}