@import '../../../styles/common/variables.scss';

.box-loader {
  opacity: 0;
  height: 0px;
  width: 0px;
  transition: opacity .2s ease-in-out;
  &.active {
    opacity: 1;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,0.7);
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    height: 100vh;
    width: 100vw;
  }
}
////////////////////////////////////////////
.loader-spinner {
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  -webkit-perspective: 1000px;
  perspective: 1000px;
  border-radius: 50%;
}
.loader-spinner {
	&:before,
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: inherit;
		height: inherit;
		border-radius: 50%;
		animation: 1.3s spin linear infinite;
	}
	&:before {
    color: $accent;
		transform: rotateX(70deg);
	}
	&:after {
    color: $dark-blue-extra;
		transform: rotateY(70deg);
		animation-delay: .4s;
	}
}

@keyframes rotate {
	0% {
		transform: translate(-50%, -50%) rotateZ(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotateZ(360deg);
	}
}

@keyframes rotateccw {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

@keyframes spin {
	0%,
	100% {
		box-shadow: .2em 0px 0 0px currentcolor;
	}
	12% {
		box-shadow: .2em .2em 0 0 currentcolor;
	}
	25% {
		box-shadow: 0 .2em 0 0px currentcolor;
	}
	37% {
		box-shadow: -.2em .2em 0 0 currentcolor;
	}
	50% {
		box-shadow: -.2em 0 0 0 currentcolor;
	}
	62% {
		box-shadow: -.2em -.2em 0 0 currentcolor;
	}
	75% {
		box-shadow: 0px -.2em 0 0 currentcolor;
	}
	87% {
		box-shadow: .2em -.2em 0 0 currentcolor;
	}
}

//////////////////////////////////

[class*="loader-spinner"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
}



//// Loader to forms management view
.forms-management-content {
  position: relative;
}
[class*="loader-form"] {
	display: inline-block;
	width: 1em;
	height: 1em;
	color: inherit;
	vertical-align: middle;
	pointer-events: none;
}
.page-overlay {
  opacity: 0;
  height: 0;
  transition: opacity .2s ease-in-out;
}
.page-overlay.active {
  position: absolute;
  height: 100%;
  top: 0; bottom: 0; left: 0; right: 0;
  background-color: rgba(255,255,255,.6);
  z-index: 1;
  opacity: 1;
  .loader-form {
    width: 100px;
    height: 100px;
    position: fixed;
    top: calc(50vh - 50px);
    left: calc(50vw - 50px);
    border: 1.2em solid $accent;
    border-bottom-color: transparent;
    border-radius: 50%;
    animation: 1.2s loader-form linear infinite;
    opacity: .6;
  }
  .loader-spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  @keyframes loader-form {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.label-loader{
  color:$accent ;
  font-size: 24px;
  margin-top: 20px;
}