@import '../../../styles/common/variables.scss';

.container-carrusel-ayuda {

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 30px;

}

.img-carrusel-ayuda {
  background: #FFFFFF;
  box-shadow: 5px 5px 10px #e0e0e0, -5px -5px 10px #ffffff;
  height: 576px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .img-carrusel-ayuda {
    height: 290px;

  }
}

.dark-bg {
  background-color: rgb(255, 240, 243);
}

.carousel-indicators {
  margin-top: 10%;
}

.prev:hover {
  border-radius: 50px;
  background: #FFFFFF;
  box-shadow: 5px 5px 10px #e8e8e8,
    -5px -5px 10px #ffffff;
}

.next:hover {
  border-radius: 50px;
  background: #FFFFFF;
  box-shadow: 5px 5px 10px #e8e8e8,
    -5px -5px 10px #ffffff;
}

.container-button-general-help {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 9;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: rgba(64, 156, 206, 0.7);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.container-button-general-help img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
    filter: drop-shadow(0 0 0 transparent);
  }

  50% {
    transform: translateY(-10px);
    filter: drop-shadow(0 15px 10px rgba(0, 0, 0, 0.5));
  }

  100% {
    transform: translateY(0);
    filter: drop-shadow(0 0 0 transparent);
  }
}

.float {
  animation: floatAnimation 3s ease-in-out infinite;
}

.container-button-general-help:hover {
  cursor: pointer;
}

.container-button-general-help::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 50px;
  transform: translateX(-50%);
  width: 50px;
  height: 15px;
  border-radius: 50%;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.container-button-general-help:hover::after {
  opacity: 1;
}

.container-button-general-help:hover {
  animation-play-state: paused;
}

@media only screen and (max-width: 600px) {
  .container-button-general-help {
    width: 40px;
    height: 40px;
    right: 25px;
    bottom: 25px;
  }

  .container-button-general-help img {
    width: 25px;
    height: 25px;
  }

}