.link-document-management {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 3px;
    width: auto;
    padding: 10px;
    background-color: #eeeeee;

}

.link-document-management:hover {
    background-color: #eeeeee;

}

.prestacion-name-management {
    margin: 0;
    padding: 0;
}

.name-management-item {
    display: flex;
    text-align: left;
    margin-bottom: 6px;
}

.description-management-item {
    margin-left: 0px;
    display: flex;
    text-align: left;
    margin-bottom: 6px;
    white-space: pre-line;
    // background-color: bisque;

}

.descargar-pdf-container {
    display: flex;
    align-items: center;
    justify-content: end;
}

.prestacion-name-management {
    text-align: left;
}

.name-management-item:hover {
    background-color: #eeeeee;
}

.container-data-management-items {
    display: flex;
    flex-direction: column;

}

.container-items {
    display: flex;
    text-align: left;
    flex-direction: column;
}
@media (min-width: 375px) and (max-width: 648px) {
    .link-document-management {
        flex-direction: column;
        margin-bottom: 10px;
        padding: 6px;
        border-radius: 3px;
        background-color: #eeeeee;
    }
    .descargar-pdf-container {
        justify-content:start;
    }
}

