// Appointments syncfusion table 
.next-appointments {
  .e-grid .e-gridcontent tr.e-row .e-rowcell:first-child {
      border-left: 4px solid #06B6BB;
  }
}
.past-appointments {
  .e-grid .e-gridcontent tr.e-row .e-rowcell:first-child {
      border-left: 4px solid #234581;
  }
}