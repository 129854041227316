.application-content {
  button {
    margin-top: 32px;
    margin-right: 24px;
  }
  .application-button-container {
    display: flex;
  }
  .application-applied__message {
    height: 40px;
    margin-top: 32px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 8px;
      background-image: url('../../../img/icons/svg/botones/tramite-solicitado.svg') ;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
  ul li {
    position: relative;
    padding-left: 16px;
    &:before {
      position: absolute;
      content: '-';
      left: 4px;
      font-weight: 700;
    }
  }
}