// @import '../../../styles/common/variables.scss';

body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
}

.main-container {
  min-height: calc(100vh - 324px);
  padding-bottom: 1px;
}

.child-container {
  padding: 0px;
}

section {
  margin: 60px auto;
  @media (max-width: 768px) {
    margin: 40px auto;
  }
  .section-title {
    font-size: 1.6em;
    color: #409cce;
    margin-bottom: 20px;
    &.uppercase {
      text-transform: uppercase;
    }
  }
}

.form-error {
  margin: 0px;
  color: #ff0000;
}
.error-download-img {
  margin: 12px;
  padding: 40px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  color: #4E4E4E;
  background-color: #f7f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-btn-close-error-img {
  display: flex;
  align-items: end; 
  justify-content: flex-end;
}
.error-otp{
  margin: 12px;
  padding: 6px;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  color: #4E4E4E;
  background-color: #f7f4f4;
 
  p{
    margin: 0px;
  }
}
.form-error-general {
  color: #ff0062;
}

.text-em_dark-blue {
  font-weight: 600;
  color: $dark-blue-extra;
}

button:disabled {
  cursor: pointer;
  pointer-events: none;
}

.btns-upon-box-container {
  margin-bottom: 16px;
  text-align: right;
  text-transform: uppercase;
  .btn-text {
    text-align: right;
    padding-right: 16px;
    font-weight: 600;
    text-transform: uppercase;
    span:first-child {
      padding-right: 8px;
    }
  }
  img {
    margin-right: 8px;
  }
  .e-icons {
    font-size: 14px;
  }
  a {
    display: flex;
    img {
      height: 24px;
    }
  }
}

.loader-text {
  margin-top: 16px;
  color: $accent;

}
.c-right {
  text-align: right;
}
.c-center {
  text-align: center;
}

.popup-advice-bottom {
  padding: 60px 0;
  position: fixed;
  bottom: 0;left: 0;right: 0;
  z-index: 9999;
  @media (max-width: 768px) {
    padding: 32px 0px;
    top: 0;
  }
}

