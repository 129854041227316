@import '../../../styles/common/variables.scss';
.form-template {
  font-size: 1rem;
  h2 {
    margin-bottom: 24px;
    font-size: 1.8em;
    font-weight: 200;
  }
  h2 + .grid {
    margin-top: 0px;
  }
  h2, h3 {
    color: $accent;
  }
  h3 {
    font-size: 1.4em;
    font-weight: 400;
  }
  .group {
    border: 1px solid black;
    border-radius: 4px;
    margin: 0px;
    padding: 24px;
    &:nth-child(odd) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
    h3 {
      margin-bottom: 16px;
    }
    .fields {
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    }
    .paragraph_field {
      margin: 24px 0px 16px;
      display: block;
      width: 100%;
      &+.paragraph_field {
        margin-top: 0px;
      }
    }
    .grid {
      margin-top: 40px;
      position: relative;
      width: 100%;
      .table-container {
        width: 100%;
        overflow-x: auto;
      }
      .grid__name {
        padding-left: 10px;
        font-size: 1.4em;
        color: black;
        font-weight: 400;
      }
      .grid__empty-message {
        margin-top: 16px;
      }
      .paragraph_field {
        margin: 10px;
        width: auto;
        &+.table-container table {
          margin-top: 8px;
        }
      }
      // table
      table {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 8px;
        table-layout: fixed;
        border-collapse: collapse;
        border-left: 1px solid $soft-gray;
        border-right: 1px solid $soft-gray;
        th, td {
          border-left: 1px solid $soft-gray;
          border-right: 1px solid $soft-gray;
        }
        th {
          // width: 23vw;
          padding: 10px;
          border-bottom: 1px solid $soft-gray;
          border-top: 1px solid $soft-gray;
          background-color: $light-gray;
          @media all and (max-width:992px) {
            width: calc(50vw - 50px);
          }
          @media all and (max-width:768px) {
            width: calc(100vw - 100px);
          }
        }
        td {
          padding: 10px;
          padding-right: 10px;
          border-bottom: 1px solid $soft-gray;
          text-align: center;
          input {
            width: 100%;
          }
          input[type=checkbox], input[type=radio] {
            min-width: 16px;
            min-height: 16px;
          }
        }
        td:first-child, table th:first-child {
          border-left: none;
        }
        td:last-child, table th:last-child {
          border-right: none;
        }
        .input-block {
          margin: 0px;
        }
       
        .input-grid {
          background-color: $light-gray;
          border-bottom: 2px solid $soft-gray;
          transition: all .2s ease-in-out;
          &:focus {
            border-bottom: 2px solid $accent;
          }
        }
      }
      .btns-container {
        display: flex;
      }
      .btn-clear {
        display: flex;
        align-items: center;
        color: $accent;
        &:disabled {
          color: $medium-gray;
          .e-icons:before {
            color: $medium-gray;
          }
          &:hover {
            color: $medium-gray;
            cursor: auto;
            .e-icons:before {
              color: $medium-gray;
            }
          }
        }
        &:hover, &:hover .e-icons:before {
          color: $medium-blue;
        }
        span {
          pointer-events: none;
        }
        .icon {
          padding-right: 4px;
          font-size: 20px;
          font-weight: 400;
        }
        .e-icons {
          padding-right: 4px;
          &::before {
            color: $accent;
          }
        }
        &.btn-clear-accent {
          color: $accent;
          &:hover {
            color: $medium-blue;
          }
        }
      }
    }
    input, select {
      min-height: 24px;
      padding-top: 4px;
      padding-bottom: 4px;
      margin-bottom: 0px;
      box-sizing: border-box;
      line-height: 24px;
    }
    .input-block {
      position: relative;
      margin: 16px 8px;
      &.inputs-group {
        display: block !important;
      }
      &.inputs-group .input-block{
        margin-top: 0px;
      }
      &.checkbox, &.radio {
        width: calc(100% - 16px) !important;
        display: flex;
        align-items: center;
        margin-bottom: 0px;
        label {
          margin: 0px;
        }
        input {
          width: auto;
          margin-left: 8px;
        }
        input[type=checkbox], input[type=radio] {
          min-width: 16px;
          min-height: 16px;
        }
      }
      legend {
        font-size: 1.2em;
        color: black;
      }
      
      &[data-25], &[data-1],&[data-width="25"] {
        width: calc(25% - 16px);
        @media all and (max-width:992px) {
          width: calc(50% - 16px);
        }
        @media all and (max-width:768px) {
          width: calc(100% - 16px);
        }
      }
      &[data-50], &[data-width="50"] {
        width: calc(50% - 16px);
        @media all and (max-width:992px) {
          width: calc(100% - 16px);
        }
        @media all and (max-width:768px) {
          width: calc(100% - 16px);
        }
      }
      &[data-75] {
        width: calc(75% - 16px);
        @media all and (max-width:992px) {
          width: calc(100% - 16px);
        }
        @media all and (max-width:768px) {
          width: calc(100% - 16px);
        }
      }
      &[data-100],&[data-width="100"] {
        width: calc(100% - 16px);
      }
      label {
        display: block;
      }
      input, select, textarea {
        box-sizing: content-box;
        width: calc(100% - 24px);
        padding: 4px 12px;
        overflow: hidden;
        background-color: $light-gray;
        border-bottom: 2px solid $soft-gray;
        transition: border-bottom .2s ease-in-out;
        color: black;
        &::placeholder {
          color: $soft-gray;
        }
        &:focus {
          border-bottom: 2px solid $accent;
        }
      }
      textarea {
        overflow-y: auto;
        min-height: 38px;
      }
      input::placeholder {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px;
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px #eeeeee inset;
    transition: background-color 5000s ease-in-out 0s;
  }

}

.form-action {
  display: flex;
  justify-content: flex-end;
  .btn-box {
    width: 200px;
    display: flex;
    align-items: center;
    .e-MT_Code::before {
      color: white;
      font-size: 20px;
      margin-right: 8px;
    }
  }
}
.form-overlay {
  &.loading {
    opacity: 1;
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    background-color: rgba(255, 255, 255,0);
    z-index: 10;
  }
}
.icon-help-delta{
  width: 20px;
  height: 20px;
  cursor: pointer;
}
@media print {
  .icon-help-delta{
    width: 20px;
    height: 20px;
  }

}