@import '../../../styles/common/variables.scss';

.container-excepciones-cita {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.excepcion-text {
    font-size: 18px;
    text-align: justify;
}

.container-text-excepcion-cita {
    background-color: $light-gray;
    padding: 40px;
    box-shadow: $boxshadow-expanded;

}

.list-excepcion {
    list-style-type: disc;
    margin-left: 30px;
}

.cards-centers-exceptions-appointments {
    width: 80%;
}

@media (max-width: 767px) {
    .excepcion-text {
        font-size: 14px;
    }

    .container-text-excepcion-cita {
        padding: 20px;
    }

    .list-excepcion {
        margin-left: 20px;
    }
}

.container-centers-information-appoinment {
    background-color: white;
    margin-top: 40px;
    text-align: center;
    padding: 20px;
}
.container-cards-centers-information-appointment{
    gap: 20px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    background-color: aqua;
    
}

.excepcion-text-centers-title {
    font-size: 24px;
    padding: 10px;
}

.card-exception-appoinmet {
    margin-bottom: 20px;
    width: 100%;
    background-color: white;
    box-shadow: $boxshadow-light;
}

.card-header-exception-appoinmet {
    background-color: $accent;
    color: white;
    text-align: center;
    padding: 6px;
}

.card-footer-exception-appoinmet {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.text-muted-exception-appoinmet {
    color: $accent;
    font-weight: bolder;
    font-size: 18px;
}