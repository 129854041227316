@import '../../../styles/common/variables.scss';
// Pagination
.pagination-block {
  border-top: 1px solid $light-gray;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 7%;
  .pagination-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .pagination-list {
    display: flex;
    .page-item {
      width: 26px;
      height: 26px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &+.page-item {
        margin-left: 4px;
      }
      &.active {
        background-color:$accent;
        .page-btn {
          color: white;
        }
      }
      .page-btn {
        opacity: 1;
        transition: opacity .2s ease-in-out;
        &:disabled {
          cursor: unset;
          opacity: .6;
        }
      }
    }
  }
  .pagination-text {
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 16px;
  }
  .dropDownList {
    width: 260px;
    padding-left: 16px;
    display: flex;
    align-items: center;
    font-size: 12px;
    .pagination-text {
      min-width: 140px;
    }
    input {
      text-align: center;
    }
  }
}