@import '../../../styles/common/variables.scss';

// Home page
.home {
  section {
    margin-top: 80px;
    margin-bottom: 80px;

    @media (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .hero-container+section {
    margin-top: 40px;
  }

  .btn-text {
    font-size: 1.2em;
    font-weight: 500;
  }
}

.remarkable_section {
  padding: 80px 0px;
  width: 95%;
  margin: auto;
  margin-bottom: 60px;
  box-shadow: $boxshadow-light;

  &.bg-accent {
    background-color: $accent;
  }

  &.bg-gray {
    background-color: $light-gray;
  }

  @media (max-width: 768px) {
    padding: 60px 0px;
  }

  .content-block {
    color: $white;
    text-align: center;
    padding: 0px 16px;

    @media (max-width: 768px) {
      margin-bottom: 48px;
    }

    .img-container {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        height: 64px;
      }

      img {
        height: 70%;

        @media (max-width: 768px) {
          max-height: 60px;
        }
      }
    }

    h3 {
      margin: 24px 0px;
      font-size: 32px;
      font-weight: 400;

      @media (max-width: 768px) {
        margin: 16px 0px;
        font-size: 24px;
      }
    }
  }
}

.section-title_big {
  display: flex;
  justify-content: center;
 align-items: center;
  font-size: 2.6em;
  font-weight: 300;
  color: $accent;
  margin-bottom: 24px;

  span {
    white-space: nowrap;

  
  }
  @media (max-width: 768px) {
    display: block;
    font-size: 1.7em;
  }
}

.text-card-center {
  display: flex;
  text-align: center;
  flex-direction: column;

}

.article-title_medium {
  font-size: 1em;
  font-weight: 500;
  color: $accent;
  cursor: pointer;
}

.hero-container {
  .img-container {
    padding: 40px 0px 0px;

    img {
      width: 100%;
    }
  }
}

.next-appointment {
  padding: 40px 0px;
  margin: 0px;
  background-color: $light-gray;
  box-shadow: $boxshadow-light;

  @media (max-width: 991px) {
    margin-right: -15px;
    margin-left: -15px;
  }

  &.bg-photo {
    min-height: 290px;
    background-image: url("../../../img/02.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    // @media (max-width: 576px) {
    //     background-image: none;
    // }
  }

  .btn-round {
    margin-top: 8px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }
  }
}

.links-section {
  .link-block {
    min-height: 100%;

    @media (max-width: 768px) {
      margin-left: -15px;
      margin-right: -15px;
    }

    &.link-block-bg-img {
      .img-bg {
        background-image: url("../../../img/01.jpg");
        background-size: cover;
        min-height: 320px;
      }

      .link-content {
        background-color: $accent;
        padding: 24px;

        .section-title_big,
        .btn-text {
          color: $white;
        }

        .btn-text {
          &::after {
            content: url("../../../img/link-right-white.svg");
          }

          &:hover {
            color: $white;
          }
        }
      }
    }

    &.link-block-bg-color {
      background-image: url("../../../img/03.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 0% 70%;
      background-color: $light-gray;
      height: 100%;
      padding: 24px;

      @media (max-width: 768px) {
        min-height: 280px;
        background-size: 15%;
        background-position: 0 50%;
      }
    }
  }

  .link-block-center {
    margin-top: 80px;
    text-align: center;

    @media (max-width: 768px) {
      margin-top: 40px;
      font-size: 1.7em;
    }

    .section-title_big {
      margin-bottom: 24px;
    }
  }
}

div.centers-section {

  margin-bottom: 60px;
 


  @media (max-width: 992px) {
    text-align: center;
  }

  .centers-link-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #EEEEEE;
    box-shadow: $boxshadow-light;

    .centers-img-container {
      position: relative;
      width: 250px;
      padding-bottom: 250px;
      border-radius: 100%;
      overflow: hidden;
      display: inline-block;
      box-shadow: inset 10 10 10px rgba(0, 0, 0);
      

      img {
        position: absolute;
        top: 0;
        bottom: -4%;
        left: -34%;
        right: 0;
        margin: auto;
        height: 104%;
      }
    }

    .section-title_big {
      margin-top: 16px;
      margin-bottom: 16px;

      @media (max-width: 992px) {
        margin-top: 32px;
        font-size: 1.7em;
      }
    }

    .btn-text {
      @media (max-width: 992px) {
        display: inline-block;

      }
    }
  }

  .centers-list {
    padding: 20px;


    li {
      margin-bottom: 24px;
    }

    p {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    a {
      font-weight: 500;
      color: black;
    }
  }
}

// block extract component
.appointment-block {
  .appointment-info {
    margin-bottom: 8px;
  }

  .appointment-header {
    font-size: 20px;
    font-weight: 500;
  }

  .appointment-detail {
    font-size: 16px;
    padding-left: 8px;
  }
}

.container-img-mobileapp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-section {
  // background-image: url("../../../img/06.png");
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;

  .descriptive-text {
    width: 100%;
  }

  .btn-round {
    margin-top: 24px;
  }
}

.container-imgs-carrusel {
  width: 100%;
  height: 500px;
  background-color: rgb(238, 238, 239);
}

.container-item-carrusel-img {
  width: 100%;
  background-image: url("../../../img/imagenCarrusel.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $light-gray;
  display: flex;
  justify-content: center;
  align-items: center;

}
@media (min-width: 320px) and (max-width: 648px) {
  .container-item-carrusel-img {
    background-image: url("../../../img/01.jpg"); 
  }
}
.opaci {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.text-label-carrusel {
  font-size: 2.6em;
  font-weight: 300;
  color: $white;
  margin-bottom: 24px;


}

.notifications-carrusel {
  background-image: url("../../../img/03.png");
  background-repeat: no-repeat;
  // background-size: 100%;
  background-color: $light-gray;
  height: 100%;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: $boxshadow-light;
}

.container-text-notifications-carrusel {

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: auto;
  margin-top: auto;
  margin-bottom: auto;

}

.container-text-img-home {
  background: rgba(0, 0, 0, 0.3);
  color: white !important;
}

.title-carrusel-record {
  background-color: #EEEEEE;
  margin-bottom: 0;

}

.container-historial-carrusel {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 80%;
  color: black;
}

.grid-record-home {
  width: 75% !important;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #409CCE !important;
}

.body-card-center {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-columns {

  column-count: 2;

  @media (max-width: 992px) {
    column-count: 1;

  }

}

.card {
  box-shadow: $boxshadow-light;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out;

  &:hover {
    box-shadow: $boxshadow-expanded;
  }
}

.text-address-card {
  font-size: 14px;
  font-weight: bold;
}

.card-footer {
  display: flex;
  text-align: center;
}

.text-muted a {
  font-size: 14px !important;
  color: #409CCE !important;
  font-weight: bold !important;
  margin: auto !important;
}

.card-header {
  color: aliceblue;
  background-color: #409CCE;
}
.prev {
  background-color: white;
  height: 50px;
  width: 50px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 100%;
  margin-left: 20px;
}
.next {
  background-color: white;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 20px;
  color: white;
}

  .separator-triangle {
    position: relative;
    background: #212529;
    height: 100px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
   
  }
  
  .separator-triangle::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 50px;
    border-color: #212529 transparent transparent transparent;
    left: 20%;
    transform: translateX(-50%) translateY(100%);
  }

 
    .separator-triangle-reverse {
      position: relative;
      background: $medium-gray;
      height: 100px;
      margin-top: 0 !important;
      margin-bottom: 0px !important;
    }
    
    .separator-triangle-reverse::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 0;
      top: -50px;
      border-style: solid;
      border-width: 0 50px 50px 50px;
      border-color: transparent transparent $medium-gray transparent;
      left: 50%;
      transform: translateX(-50%);
    }
    .container-info-royal-decree-form {
      background-color: $light-gray;
      padding: 0;
    }
    .container-texts-info-royal-decree-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 24px;
      background-image: url('../../../img/icons/symbols/senal-de-advertencia.png');
      background-repeat: no-repeat;
      background-size: contain; 
      background-position: -70px -5px ; 
      box-shadow: $boxshadow-light;
    }
    .container-img-text-info-royal-decree-form {
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: rgba(255, 255, 255, 0.3);
      // box-shadow: $boxshadow-light;
      width: 80%;
      padding: 10px;
     
    }
    .img-info-royal-decree-form {
      width: 300px;
      height: 150px;
    }
    .text-info-royal-decree-form {
      font-size: 18px;
      text-align: justify;
      color: #39393a;
    }
    @media (max-width: 768px) {
      .section-title_big {
        font-size: 1.7em
      };
    }
