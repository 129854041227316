
// Communication opening
.communication-opening {
  text-align: center;
  .icon-container {
    margin-bottom: 24px;
  }
  .btn-box {
    margin-top: 16px;
  }
}