// external
@import './vendors/bootstrap-grid';
/* vendors */
@import 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css';
@import 'https://cdn.syncfusion.com/ej2/material.css';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

//common
@import './common/reset';
@import './common/variables';
@import './common/general.scss';
@import './common/buttons.scss';
@import './common/inputs.scss';
@import './common/syncfusion-icons.scss';
@import './common/syncfusion-overwritten.scss';
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

// blocks
@import './components/blocks/forms.scss'; 
@import './components/blocks/grids.scss'; 
@import '../components/blocks/ToastNotification/toast-notification.scss'; // useSignalR lo necesita


@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";