@import '../../../styles/common/variables.scss';

.forms-tabs {
  padding: 0px 15px; // no col bootstrap
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;

  .tab-item {
    position: relative;
    border-right: 4px solid white;
    border-left: 4px solid white;
    margin: 32px 8px 0px;

    &+.tab-item::before {
      content: '';
      position: absolute;
      width: calc(100% + 24px);
      height: 1px;
      top: 0;
      bottom: 0;
      left: -24px;
      margin: auto;
      background-color: $soft-gray;
      z-index: -1;
    }

    &:last-of-type::before {
      // width: calc(100% + 12px);
    }

    &:first-of-type::before {
      left: 0;
    }

    .tab-btn {
      position: relative;
      width: 160px;
      height: 100px;
      padding: 24px 16px 16px;
      border-radius: 2px;
      background-color: $light-gray;
      transition: all .2s ease-in-out;

      .tab-btn_number {
        position: absolute;
        top: -16px;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        background-color: $soft-gray;
        border-radius: 100%;
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 32px;
      }

      .tab-btn_title {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      &:hover,
      &.active {
        background-color: $extra-light-blue;

        .tab-btn_number {
          background-color: $light-blue;
          color: $white;
        }
      }

      &.active {
        pointer-events: none;
      }
    }

    &:first-of-type {
      border-left: 0px;
      margin-left: 0px;
    }

    &:last-of-type {
      border-right: 0px;
      margin-right: 0px;
    }
  }
}

.actions-forms {
  margin-top: 40px;
  margin-bottom: 80px;

  &.loading-active-r {
    float: right;
  }
}

.container-btn-direct-pay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  line-height: 1.3em;
  

}

.pago-directo-iframe {
  min-width: 100%;
  height: 1600px;
  padding-top: 20px;
  background: #eeeeee;
  margin-bottom: 30px;
 
}

.btn-direct-pay {
  color: white;
  border-radius: 6px;
  width: 100px;
  height: 30px;
  justify-content: center;
  align-items: center;
  background: $accent;
}

.text-direct-pay {
  color: gray;
}

.text-direct-pay-bold {
  color: gray;
  font-weight: bold;
}

.view-form {
  display: flex;
  justify-content:center;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.btn-direct-pay:hover {
  cursor: pointer;
}

.visible {
  display: block;
  z-index: 6 !important;

}

.hidden {
  display: none;
 
}
.header-acordeon-title {
  background-color: #eeeeee;
  box-shadow: $boxshadow;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: $boxshadow-expanded;
  }
}

.accordion-button:not(.collapsed){
  background-color: #00A5BF;
  box-shadow: $boxshadow;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: $boxshadow-expanded;
  }
  color: #eeeeee;
  border: none !important;
}
.actives:not(.collapsed) {
  background-color: #015c83;
}
.title-benefit {
  color: #409CCE;
}
.name-management-item {
  color: #409CCE;
  cursor: pointer;
  font-size: 24px;
}
.title-gestion {
  font-size: 24px;
  // width: 360px;
}

.description-management-item {
  color: rgb(109, 110, 110);
}
.without-active-benefit {
  color:rgb(159, 160, 160);
 margin-left: auto;
}
.bg-groups-active {
  background-color: rgb(248, 250, 250);
  box-shadow: $boxshadow;
  transition: box-shadow .2s ease-in-out;
  &:hover {
    box-shadow: $boxshadow-expanded;
  }
  margin-top: 20px;
}
.description-group {
  padding: 6px;
  color: rgb(109, 110, 110);
  background-color: white;
}

@media screen and (max-width: 414px) {
  .title-gestion {
    font-size: 12px;
   
  }
  .without-active-benefit {
    font-size: 12px;
  }
}