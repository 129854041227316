.role-selection-wrapper {
  min-height: 100vh;
  padding-top: 80px;
  background-color: #eeeeee;
  text-align: center;
  h1 {
    img {
        width: 256px;
    }
    margin-bottom: 40px;
  }
  h2 {
    span {
        font-weight: 500;
    }
    margin-bottom: 40px;
  }
}