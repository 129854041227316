.toast-notification {
  max-width: 358px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 8px;
  padding-left: 8px;
  background-color: $accent;
  color: $white !important;
  display: flex;
  align-items: center;
  transition: color .4s;
  &:hover {
    color: $white;
  }
  .icon-bell {
    min-width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 12px solid $gray-blue;
    margin-right: 16px;
    background-color: $gray-blue;
    background-image: url('../../../img/icons/svg/popups/campana-azul.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  div {
    max-width: calc(100% - 80px);
  }
  .title {
    margin-bottom: 8px;
    font-size: 1.2em;
  }
  .text {
    margin: 0px;
    max-width: 100%;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;  
  }
}
.Toastify__toast-container {
  width: 400px !important;
  max-width: calc(100vw - 40px) !important;
  @media only screen and (max-width: 480px) { // todo: test in mobile
    // width: auto !important;
    // right: 16px !important;
  }
}
.Toastify__toast--default {
  background: $accent !important;
  border-radius: 4px !important;
}
.Toastify__close-button--default {
  color: $white !important;
  opacity: 1 !important;
  svg {
    height: 20px !important;
    width: 18px !important;
  }
}
.Toastify__toast-container--top-right {
  top: 80px !important;
}