@import '../../../styles/common/variables.scss';

.container-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    margin-top: 50px;
 

}

.img-no-data {
    width: 80px;
    height: 80px;
}
.message-no-data{
    text-align: center;
    margin-top: 10px;
    color: rgb(151, 150, 150);
}
.card-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    padding: 16px;
    border: 1px solid #eeeeee;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 24px;
    transition: box-shadow 0.2s ease-in-out;
    &:hover {
        box-shadow: $boxshadow-expanded;
      }
}
.into-no-data {
    width: 310px;
    height:auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(241, 244, 251, 0.9);
}