@import '../../../styles/common/variables.scss';
// SideMenu
.side-menu-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  visibility: visible;
  .bg {
    width: 100%;
    height: 100%;
    background-color: rgba(32, 32, 32, 0.6);
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s linear, opacity 0.3s linear, background-color 0.5s ease-in-out;
  }
  &.collapsed {
    visibility: hidden;
    .bg {
      visibility: hidden;
      opacity: 0;
      background-color: rgba(32, 32, 32, 0);
    }
    .side-menu_block {
      right: -400px;
      @media (max-width: 768px) {
        right: -100vw;
      }
    }
  }
  &.company {
    .side-menu_block {
      background-color: $accent-company;
    }
  }
  .side-menu_block {
    width: 400px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #234581; // bg just for patient bg
    padding: 16px 16px 32px 32px;
    transition: all 0.5s ease-in-out;
    .nav-list-container {
      position: relative;
      &:after {
        position: absolute;
        content: '';
        top: 0; left: 0; right: 0;
        height: 20px;
        // background: rgb(58,95,161);
        // background: linear-gradient(180deg, rgba(35, 69, 129,1) 35%, rgba(0,212,255,0) 100%);
      }
      &::before {
        position: absolute;
        content: '';
        bottom: 0; left: 0; right: 0;
        height: 20px;
        // background: rgb(58,95,161);
        // background: linear-gradient(0deg, rgba(35, 69, 129,1) 35%, rgba(0,212,255,0) 100%);
      }
      nav {
        padding: 20px 0px 10px 20px;
        height: calc(100vh - 160px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          height: 20px;               /* width of the entire scrollbar */
          width: 8px;
          scrollbar-width: thin;
          margin-top: 40px;
          margin-bottom: 40px;
        }
        &::-webkit-scrollbar-track {
          background: $accent-company;        /* color of the tracking area */
          margin-left: 4px;
          margin-right: 4px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $dark-blue-extra-company;    /* color of the scroll thumb */
          border: 3px solid $dark-blue-extra-company;  /* creates padding around scroll thumb */
          border-radius: 8px;       /* roundness of the scroll thumb */
          transition: all .2s ease-in-out;
          &:hover {
            background-color: $dark-blue-extra-company;    /* color of the scroll thumb */
            border: 3px solid $dark-blue-extra-company;  /* creates padding around scroll thumb */
          }
        }
      }
    }
    
    @media (max-width: 768px) {
      width: 100vw;
    }
    .btn-close {
      img {
        width: 24px;
      }
      margin-bottom: 16px;
      text-align: right;
    }
    .btn-close:hover {
      cursor: pointer;
    }
    .user-info {
      display: flex;
      color: #85d4ff;
      .icon-container {
        img {
          height: 44px;
          margin-right: 16px;
        }
      }
      .user-info-text {
        .user-name {
          font-size: 24px;
          text-transform: capitalize;
        }
        .user-type {
          margin: 0px;
        }
      }
    }
    nav {
      margin-top: 16px;
      a,
      div {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
        & + a,
        + div {
          margin-top: 24px;
        }
        .link-text {
          padding-left: 16px;
        }
        &.selected {
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 2px;
        }
      }
      &:hover {
        color: $white;
      }
      img {
        width: 24px;
        height: auto;
      }
    }
  }
}
