@import '../../../styles/common/variables.scss';




// Header
.header-block {
  background-color: $accent;
  box-shadow: $boxshadow-light;

  &.company {
    background-color: $accent-company;

    .header-content {
      .nav-container {
        .nav_link {
          background-color: $accent-company;

          &:hover,
          &.selected {
            background-color: $dark-blue-extra-company;
          }
        }
      }
    }
  }

  &.impersonation {
    background-color: $light-blue;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-block {
      width: 200px;
      height: 73px;
      position: relative;

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        z-index: 2;
        height: 74px;
        // border-radius: 0px 0px 4px 4px;
        text-align: center;

        // background: linear-gradient(#fff, #ddd);
        // background-color: #409CCE;
        // box-shadow: $boxshadow;
        // box-shadow: inset 0 0 35px 5px rgba(0,0,0,0.25), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px 0 rgba(0,0,0,0.25);
        .logo {
          // padding: 20px 20px 24px 20px;
          width: 100%;
          height: 100%;

        }

      }

      @media (max-width: 768px) {
        width: 140px;
        height: 56px;

        .logo-container {
          height: 60px;

          .logo {
            width: 140px;
          }
        }
      }
    }

    .nav-container {
      display: flex;
      justify-content: flex-end;

      .nav_filter {
        width: 200px;
        padding-right: 16px;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          border: 1px solid $medium-blue;
          border-radius: 4px;
          background-color: white;
        }
      }

      .nav_link {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 16px;
        color: $white;
        background-color: $accent;
        transition: background-color 0.2s ease-in-out;

        &:hover,
        &.selected {
          background-color: $medium-blue;
        }

        &+.nav_link {
          margin-left: 6px;
        }

        &:last-child {
          margin-right: 24px;
        }

        .nav_link-icon {
          padding-right: 4px;

          img {
            width: 18px;
          }
        }

        .nav_link-number {
          padding-left: 4px;
        }
      }
    }

    .links-wrapper {
      display: flex;
    }
  }

  .toggle_btn {
    display: inline-block;
    margin-right: 16px;

    &+.toggle_btn {
      margin-right: 0px;
    }

    cursor: pointer;

    img {
      width: 24px;
    }
  }
}

@media print {
  .logo-print {
    width: 20px !important;
    height: 20px !important;
    margin-bottom: 200px !important;


  }
}
.container-aplication-ext {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 150px;
  margin-left: 5%;
  background-color: $accent;
  color: rgb(124, 121, 121);
  background: rgb(250, 250, 250);
  border-radius: 5px;
  box-shadow: inset 0 0 35px 5px rgba(0,0,0,0.25), inset 0 2px 1px 1px rgba(255,255,255,0.9), inset 0 -2px 1px 0 rgba(0,0,0,0.25);
p{
  margin: 0px;
}
};
.p-aplication-ext{
  color: rgb(238, 233, 233);
  text-align: center;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 5px;
  margin-bottom: 0px;
  border-radius: 5px;
  margin-bottom: 5px;


}
