@import '../../../styles/common/variables.scss';
.article-home-link {
  position: relative;
  min-height: 220px;
  h2 {
    font-size: 24px;
  }
  img {
    display: block;
    max-width: 100%;
    width: 100%;
    margin: 16px 0px;
  }
  .btn-text.icon-right {
    display: inline-block;
    font-size: 20px;
    font-weight: 500;
  }
  &.app {
    border: 1px solid $medium-gray;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    div {
      padding-right: 16px;
      padding-bottom: 16px;
    }
    h2 {
      width: 100%;
    }
    img {
      position: absolute;
      top: -16px; left: 0; right: 0;
      width: initial;
      margin: auto;
      z-index: 1;
    }
  }
}