.label-block {
    display: flex;
    position: relative;
    margin-bottom: 16px;
}

.float-label {
    height: 20px;
    padding: 0px 4px;
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 14px;
    margin: auto;
    color: $medium-gray;
    background-color: $white;
    transition: all .2s ease-in;
}

.input {
    width: 100%;
    min-width: 200px;
    border-radius: 4px;
    height: 32px;
    padding-left: 16px;
    border: 1px solid $light-gray;
    background-color: $white;
    transition: all .2s ease-in;

    &.textarea {
        padding-top: 8px;
        font-family: inherit;
        min-height: 100px;
        resize: none;
    }

    &:-webkit-autofill {
        background-color: $white !important;
        transition: background-color 5000s ease-in-out 0s;

        ~.float-label {
            height: 10px;
            font-size: 12px;
            text-transform: uppercase;
            top: -36px;
        }
    }

    &:hover {
        border: 1px solid $dark-gray;
    }

    &:not([disabled]):focus {
        border: 1px solid $accent;
        -webkit-box-shadow: 0px 0px 6px 0px rgba(64, 156, 206, .4);
        -moz-box-shadow: 0px 0px 6px 0px rgba(64, 156, 206, .4);
        box-shadow: 0px 0px 6px 0px rgba(64, 156, 206, .4);

        ~.float-label {
            color: $accent;
        }
    }

    &.invalid {
        border: 1px solid red;
        box-shadow: none;
    }

    &:focus,
    &.typed {
        ~.float-label {
            height: 10px;
            font-size: 12px;
            text-transform: uppercase;
            top: -36px;
        }
    }
}


/* Chrome, Safari, Edge, Opera */
input.no-arrow::-webkit-outer-spin-button,
input.no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.no-arrow[type=number] {
    -moz-appearance: textfield;
}