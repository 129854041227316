@import '../../../styles/common/variables.scss';

// cardLink
// .container-card-link-color{
//   display: flex;
//   background-color: #234581;

// }
.card-link-block {
  display: flex;
  flex-direction: column;
  // height: calc(100% - 24px);
  width: 100%;
  padding: 16px;
  border: 1px solid $light-gray;
  box-shadow: $boxshadow-light;
  margin-bottom: 24px;
  cursor: pointer;
  transition: box-shadow .2s ease-in-out;

  &:hover {
    box-shadow: $boxshadow-expanded;
  }

  &.colored {
    &[aria-disabled="true"] {
      pointer-events: none;
      cursor: auto;

      &:hover {
        box-shadow: $boxshadow-light;
      }
    }

    &.linkDisabled {
      border-left: 4px solid $light-gray;
    }

    &.clear {
      border-left: 4px solid #7dacfc;
    }

    &.bright {
      border-left: 4px solid rgb(5, 183, 189);
    }

    &.dark {
      border-left: 4px solid #234581;
    }

    &.undetermined {
      border-left: 4px solid #f9d59c
    }
    &.videoCall {
      // background-color:aliceblue;
       border: 1.5px solid #06B6BB;
    }
  }

  .container-title-description {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .img-calendar-appointment-card {
    width: 30px;
    height: 30px;
    margin-bottom: 20px;

  }

  .date-hour-container {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    

  }

  .container-date {
   .label-date {
    font-size: 12px;
    color: rgb(151, 150, 150);
    margin: 0px;
   }
  }

  .container-hour {
    .label-hour {
      font-size: 12px;
      color: rgb(151, 150, 150);
      margin: 0px;
     }
  }
  .text-date {
    color: rgb(11, 11, 11);
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    margin: 0px;
  }
  .text-hour {
    color: rgb(11, 11, 11);
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
    margin: 0px;
  }
  .text-mensaje-appointment {
    font-size: 12px;
    color: rgb(151, 150, 150);
    margin: 0px;
  }

  .exp-num {
    font-size: 12px;
    color: rgb(151, 150, 150);
    font-weight: bold;
  }

  .card-link_title {
    color: rgb(11, 11, 11);
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
  }

  .text-header {
    font-weight: 200;
  }

  .text {
    color: rgb(151, 150, 150);
    min-height: 22px;
    margin-bottom: 0;
    font-size: 14px;
  }

  .text-info-card {
    color: rgb(151, 150, 150);
    min-height: 22px;
    font-size: 14px;
    margin-top: auto;
    margin-bottom: 0%;

  }

  .center-appointment {
    margin-top: 20px;

  }

  .btn-text {
    display: block;
    margin-top: 16px;
    text-align: right;
    font-weight: 500;
    text-transform: uppercase;
  }

  &.disabled {
    opacity: .6;
    cursor: auto;
    pointer-events: none !important;

    .btn-text {
      color: $dark-gray;
    }

    &:hover {
      box-shadow: $boxshadow-light;

      .btn-text {
        color: $dark-gray;
      }
    }
  }
}
.container-icon-state {
  display: flex;
  flex-direction: row;
 

  .state-appointment{
    margin-left: 6px;
    font-size: 10px;
    margin-top: 10px;
    color: #ff0000 ;
  }
  .state-appointment-grey{
    margin-left: 6px;
    font-size: 10px;
    margin-top: 10px;
    color: rgb(151, 150, 150) ;
  }
}
.rescheduled {
  background-color: rgba(241, 244, 251, 0.9);
}
.annulled {
  background-color: rgba(241, 244, 251, 0.9);
}
.unrealized {
  background-color: rgba(241, 244, 251, 0.9);
}
