@import '../../../styles/common/variables.scss';
.container-terminos-condiciones{
    margin-bottom: 70px;
   
}

.logo-landingpage {

    width: 140px;
    height: 37px;
}

.data-list {
    list-style-type: disc;
    margin-left: 20px;
}
.separator-triangle-reverse {
    position: relative;
    background: $medium-gray;
    height: 100px;
    margin-top: 0 !important;
    margin-bottom: 0px !important;
  }
  
  .separator-triangle-reverse::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    top: -50px;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent $medium-gray transparent;
    left: 50%;
    transform: translateX(-50%);
  }
  .separator-triangle {
    position: relative;
    background: #eeeeee;
    height: 82px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    box-shadow: $boxshadow-light
   
  }
  
  .separator-triangle::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 50px 50px 0 50px;
    border-color: #eeeeee transparent transparent transparent;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
  }
  @media only screen and (max-width: 600px) {
    .separator-triangle {
        height: 101px;
    }
    .container-terminos-condiciones{
        margin-left: 0px;
        margin-right: 0px;
        padding: 10px;
        box-shadow: none;
    }
}
