@import '../../../styles/common/variables.scss';

.Accordion {
  background-color: white;

  &.expanded {
    .accordion-item {
      padding: 16px 16px 16px 16px;
      opacity: 1;
      max-height: 10000px;
      border-bottom-right-radius: 4px;
      transition: max-height .5s ease-in-out,
                  padding .25s,
                  opacity .5s .25s;
    }
    .header-icon {
      transform: rotate(180deg);
    }
  }

  .accordion-item {
    background-color: #fff;
    padding: 16px 16px 16px 16px !important;
  }

  .form--simple-styled.searcher {
    background-color: #eeeeee;
    box-shadow: $boxshadow-light;
    border-radius: 6px;
  }

  .accordion-header-block {
    background-color: #00A5BF;
    padding: 8px 16px;
    border-bottom: 1px solid gray;
    margin: 0px;

    button {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      .header-icon {
        transition: transform .5s ease-in-out;
      }
    }

    .header-text {
      height: 50px;
      color: white;
      font-size: 2em;
    }
  }

  .accordion-item {
    padding: 0px;
    margin: 0;
    max-height: 0px;
    opacity: 0;
    padding: 0;
    transition: max-height .5s ease-in-out,
                opacity .25s,
                margin .5s .25s,
                padding .5s .25s;

    &.expanded {
      padding: 16px;
      opacity: 1;
      max-height: 1000px;
      border-bottom-right-radius: 4px;
      background-color: red;
      transition: max-height .5s ease-in-out,
                  padding .25s,
                  opacity .5s .25s;
    }
  }
}

.iconDown {
  width: 50px;
  height: 50px;
}

@media (max-width: 768px) {
  .Accordion .accordion-header-block .header-text {
    font-size: 1.35em;
  }
}
