@import '../../../styles/common/variables.scss';

// record detail
.management-block {
  @media (max-width: 992px) {
    &.management-block {
      margin-top: 32px;
    }
  }

  .title-icon {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .icon {
      width: 32px;
      height: 32px;
      display: inline-block;
      margin-right: 8px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;

      &.calendar {
        background-image: url("../../../img/icons/svg/historial/historial-calendario.svg");
      }

      &.economic {
        background-image: url("../../../img/icons/svg/historial/historial-economico.svg");
      }
    }

    .text {
      font-size: 20px;
    }
  }
}

.box-info-block {
  position: relative;
  min-height: 56px;
  padding-left: 16px;
  margin-top: 8px;
  margin-bottom: 20px;
  background-color: $gray-blue; // #EEF4FE;
  color: $dark-blue-extra;
  display: flex;
  align-items: center;

  .input-container {
    input[type="checkbox"]:disabled {
      color: -internal-light-dark(rgb(241, 0, 0), rgb(209, 15, 15)) !important;
      cursor: default;
    }
  }

  p {
    label {
      margin-bottom: 0;
      margin-right: 8px;
    }

    display: inline-block;
    margin: 0px;

    &+p {
      margin-left: 24px;
    }
  }
}

.tab-menu {
  display: flex;
  flex-wrap: wrap;
  .tab-menu__btn {
    position: relative;
    width: 100%;
    padding: 12px 12px 12px 34px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    transition: .2s;

    &.active {
      pointer-events: none;
      background-color: rgba(6, 182, 188, .33);
    }

    &:hover {
      background-color: rgba(6, 182, 188, .15);
    }

    &+.tab-menu__btn {
      margin-top: 2px;
    }

    &.visit:before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/svg/historial/submenu-01.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &.rehab::before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/svg/historial/submenu-02.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &.operating::before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/svg/historial/submenu-03.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &.hospitalization::before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/svg/historial/submenu-04.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &.emergencies::before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/svg/historial/ambulance2.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &.documents::before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/svg/historial/submenu-05.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    &.image::before {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 8px;
      content: '';
      background-image: url("../../../img/icons/record/grisSmall.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}
.record-extinct{
  padding: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0px;
  background-color: rgba(220, 221, 223, 0.8);
  text-shadow: 3px 3px 2px rgba(237, 248, 249, 1);
  width: 100%;
  height: 100%;
  color: rgb(124, 123, 123);
}

.num-expediente {
font-size: 12px;
}
.container-communication-block {
  background: #eeeeee;
  box-shadow: $boxshadow-light;
  padding:20px;
  border-radius: 5px;

}