@import '../../../styles/common/variables.scss';

.article-home-box {
  position: relative;
  height: 100%;
  min-height: 220px;
  padding: 16px;
  border-radius: 2px;
  box-shadow: $boxshadow;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 32px;
  }
  .article-count {
    background-color: #cccccc;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    line-height: 44px;
    color: white;
    text-align: center;
    position: absolute;
    top: -22px;
    right: 16px;
    font-weight: 500;
    font-size: 18px;
  }
  .btn-container {
    display: none;
  }
  &.communications {
    background-image: url('../../../img/icons/svg/home-company/01.png');
    background-repeat: no-repeat;
    background-position: -40px center;
    background-size: auto 160px;
  }
  &.accident-without-leave {
    background-image: url('../../../img/icons/svg/home-company/02.png');
    background-repeat: no-repeat;
    background-position: -40px center;
    background-size: auto 160px;
  }
  &.accident-leave {
    background-image: url('../../../img/icons/svg/home-company/03.png');
    background-repeat: no-repeat;
    background-position: -40px center;
    background-size: auto 160px;
  }
  &.last-medical-discharge {
    background-image: url('../../../img/icons/svg/home-company/04.png');
    background-repeat: no-repeat;
    background-position: -40px center;
    background-size: auto 160px;
  }
  &.workers-medical-discharge {
    background-image: url('../../../img/icons/svg/home-company/05.png');
    background-repeat: no-repeat;
    background-position: -40px center;
    background-size: auto 160px;
  }
  &.active {
    color: white;
    .btn-container {
      display: block;
    }
    &.communications {
      background-color: #06B6BB;
    }
    &.accident-without-leave {
      background-color: #05a7c0;
    }
    &.accident-leave {
      background-color: #00A5BF;
    }
    &.last-medical-discharge {
      background-color: #407ece;
    }
    &.workers-medical-discharge {
      background-color: #234581;
    }
    .article-count {
      background-color: $red;
    }
  }
}