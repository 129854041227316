
.container-modalOTP {
    opacity: 0;
    height: 0px;
    width: 0px;
    transition: opacity .2s ease-in-out;  

  }
  .activeOTP {
    opacity: 1;
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    /* background-color: transparent; */
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    height: 100vh;
    width: 100vw;
   
  }
  .e-dlg-overlay {
    background-color: slategray;
    opacity: 0.6;
   
}

.container-title-new-modal {
    text-align: center;
    margin-bottom: 20px;
}
.title-otp-modal {
  color: #409CCE;
  text-align: center;
  margin-top: 20px;
}
.container-btn-sbm-otp {
  justify-content: end;
  display:flex;
  margin-top: 20px;
  width: 100%;
  
}
.btn-sbm-otp {
  background: #409CCE;
  border: none;
  align-self: flex-end;
}
.btn-sbm-otp:hover{
  background: #347BA2;
}
