@import '../../../styles/common/variables.scss';
.notifications-tabs {
  height: 36px;
  border-bottom: 1px solid $light-gray;
  // background-color: blanchedalmond;
  display: flex;
  align-items: center;
  .tab {
    height: 100%;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $link-blue;
    font-weight: 600;
    cursor: pointer;
    &.active {
      pointer-events: none;
      border-bottom: 3px solid $accent;
    }
    .news {
      position: relative;
      &:after {
        position: absolute;
        right: -8px; top: 2px;
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        -moz-border-radius: 7.5px;
        -webkit-border-radius: 7.5px;
        border-radius: 7.5px;
        background-color: red;
      }
    }
  }
}
.custom-cell-style {
  background-color: $accent !important;
  color: #fff !important;
 
}

