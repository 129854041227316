// syncfusion icons
// how to use
// <span className="e-icons e-AddComment"></span>

button:disabled {
  .e-icons {
    color: $soft-gray;
  }
}
.e-icons {
  color: $dark-gray;
}

.e-edit:before{
  content: '\e81e';
}
.e-delete:before {
  content: '\e84e';
}
.e-Up-arrow:before {
  content:'\e918';
}
.e-Dwn-arrow3:before {
  content:'\e919';
}
//
.e-AddComment:before {
  content:'\e814';
  color: #409CCE;
}
.text-response {
  color: #409CCE;
  font-weight: bold;
}
.e-ArrowLeft:before {
  font-weight: 800;
  font-size: 24px;
  content:'\e85b';
}
.e-M_Load:before {
  content: '\e710';
}

//cards
.e-th-small:before {
  content: '\e714';
}


.e-MT_Grid:before {
  content: '\e600';
}

.e-Level4:before {
  content: '\e660';
}

//lista

.e-MT_Justify:before {
  content: '\e334';
}

.e-M_Bullets:before {
  content: '\eb5b';
}

.e-Filter:before {
  content:'\e909';
}

.ascending {
  display: inline-block;
  transform: rotateZ(180deg);
  &.e-Filter:before {
    content:'\e909';
  }
}

.e-add-new:before {
  content:'\e823';
}

.e-MT_DateTime::before {
  content: '\e421'
}
.btn-response {
  background-color: white;
  width: 150px;
  display: flex;
  gap: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  border: 1px solid #409CCE;
  margin-right: 30px;
  border-radius: 5px;
  box-shadow: $boxshadow-light;
  &:hover {
    box-shadow: $boxshadow-expanded;  
    transform: scale(1.05);  
  }

}
.container-btn-response {
  background-color: #eeeeee;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: $boxshadow-light;

}
