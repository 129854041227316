.episode-info__list {
  li {
    margin-bottom: 10px;
    .header {
      font-weight: 600;
      margin-right: 4px;
    }
    .data {

    }
  }
}
.record-container-documents {
  padding: 20px;
  padding-left: 30px;
}